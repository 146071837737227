import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeebbackRoutingModule } from './feebback-routing.module';
import { FeebbackFormComponent } from './feebback-form/feebback-form.component';
import {  MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {  MatRadioModule } from '@angular/material/radio';

 
@NgModule({ 
  declarations: [FeebbackFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule
    ,
    FeebbackRoutingModule
  ]
})
export class FeebbackModule { }
