import { Injectable } from '@angular/core';
import { TokenStorageService } from './token-storage.service';
import { Store } from '@ngrx/store';
import { AuthState } from '../store/app.reducers';
import * as AuthActions from '../store/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthStateService {

  constructor(
    private store: Store<AuthState>,
    private ts: TokenStorageService
  ) {}

  async updateAuthState() {
    // Lógica para obtener y actualizar el estado de autenticación
    const isAuthenticated = this.ts.getAccessToken() !== null;
    
    if (isAuthenticated) {
      // El usuario está autenticado, actualiza el estado de @ngrx/store
      const accessToken = await this.ts.getAccessToken();
      this.store.dispatch(new AuthActions.SetToken(accessToken));
    } else {
      // Lógica para manejar el usuario no autenticado
    }
  }
}
