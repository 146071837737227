import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';

import { VexRoutes } from '../@vex/interfaces/vex-route.interface';

import { AppGuard } from './app.guard';
import { Role } from './models/work-group.model';




const routes: VexRoutes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: '',
    component: CustomLayoutComponent,
    canActivate: [AppGuard],
    children: [
      {
        path: '', redirectTo: '/principal', pathMatch: 'full'
        
      },
      {
        path: 'forbidden',
        loadChildren: () => import('./pages/errors/forbidden/forbidden-routing.module').then(m => m.ForbiddenRoutingModule)
      },
      {
        path: 'principal',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
      },
      {
        path: 'perfil',
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'agenda',
        loadChildren: () => import('./pages/meeting/meeting.module').then(m => m.MeetingModule),
        data: {
          toolbarShadowEnabled: true,
          data: { roles: [Role.Admin,Role.Secretary,Role.Professional,Role.Manager] }
        }
      },
      {
        path: 'pacientes',
        loadChildren: () => import('./pages/patient/patient.module').then(m => m.PatientModule),
      
        data: {
          toolbarShadowEnabled: true,
        
        }
        
      },

      {
        path: 'notificaciones-whatsapp',
        loadChildren: () => import('./pages/notification-chat/notification-chat.module').then(m => m.NotificationChatModule),
      
        data: {
          toolbarShadowEnabled: true,
        
        }
        
      },

      
      {
        path: 'resumen-financiero',
        loadChildren: () => import('./pages/dashboards/dashboard-finance/dashboard-finance.module').then(m => m.DashboardFinanceModule),
      
        data: {
          toolbarShadowEnabled: true,
        
        }
        
      },
      {
        path: 'resumen-clinica',
        loadChildren: () => import('./pages/dashboards/dashboard-clinic/dashboard-clinic.module').then(m => m.DashboardClinicModule),
      
        data: {
          toolbarShadowEnabled: true,
        
        }
        
      },
      {
        path: 'pedidos',
        loadChildren: () => import('./pages/product-order/product-order.module').then(m => m.ProductOrderModule),
        data: {
          toolbarShadowEnabled: true
        }
      },

      {
        path: 'notificaciones',
        loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationModule),
      },
      
      {
        path: 'enfermedades',
        loadChildren: () => import('./pages/disease/disease.module').then(m => m.DiseaseModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'ajustes',
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'productos',
        loadChildren: () => import('./pages/product/product.module').then(m => m.ProductModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
  
      {
        path: 'tratamientos',
        loadChildren: () => import('./pages/medication/medication.module').then(m => m.MedicationModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'pagos',
        loadChildren: () => import('./pages/service-payment/service-payment.module').then(m => m.ServicePaymentModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'ayuda',
        loadChildren: () => import('./pages/help-center/help-center.module').then(m => m.HelpCenterModule),
        data: {
          toolbarShadowEnabled: true
        }
      },
      {
        path: 'legal',
        loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalModule),
        data: {
          toolbarShadowEnabled: true
        }
      },

    
    ]
  },

  {
    path: '**',
    loadChildren: () => import('./pages/errors/error-404/error-404.module').then(m => m.Error404Module)
  },

  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    // relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
