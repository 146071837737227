import { ChangeDetectorRef, Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';




import { CommonService } from 'src/app/services/common.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { CollectionsFormatData } from 'src/app/models/collections-format-data';
import { of } from 'rxjs';


@Component({
  selector: 'vex-profile-user',
  templateUrl: './profile-user.component.html',
  styleUrls: ['./profile-user.component.scss']
})
export class ProfileUserComponent implements OnInit {

  private baseUri: string = '/profile-name';
  form: FormGroup;
  searchCtrl = new FormControl();




  icColorVerified = 'text-grey-50';


  constructor(
    @Inject(LOCALE_ID) private locale: string,


    private dialogRef: MatDialogRef<ProfileUserComponent>,
    private fb: FormBuilder,
    private cService: CommonService,
    private ts: TokenStorageService) {
  }

  ngOnInit() {

    this.form = this.fb.group({

      name: [null || '', [Validators.required]],


    });

    this.form.get('name').valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((typedChars: string) => {
          if (typedChars !== null && typedChars.length > 1) {
            return this.cService.getLoadQuery('/professional-name', '?query=' + typedChars);
          } else {
            return of<CollectionsFormatData>();
          }
        })
      ).subscribe((result: CollectionsFormatData) => {
        if (result.data.length > 0) {
          this.icColorVerified = 'text-red-500';
        } else {
          this.icColorVerified = 'text-green-500';

        }
      });



  }







  save(valueForm) {

    this.ts.isGroupWorkNow().then((dataGroup) => {

      let id = dataGroup.is_user;
      valueForm.is_group = dataGroup.is_group;
      valueForm.is_updated = dataGroup.is_user;
      valueForm.is_active = 1;
      this.cService.update(this.baseUri, id, valueForm)
        .subscribe({
          next: (res) => {
            switch (res.status_code) {
              case 200: {
                this.cService.show('Se actualizado correctamente ', 'success');
                this.dialogRef.close(true);
                break;
              }
              case 422: {
                for (let a of res.errors) {
                  this.cService.show(a, 'warning');
                }
                break;
              }
            }

          },
          error: (err) => { this.cService.show(err, 'error'); }
        });
    });
  }






}
