import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { fadeInUp400ms } from '../../../../@vex/animations/fade-in-up.animation';
import { AuthService } from 'src/app/services/auth.service';
import { Store } from '@ngrx/store';
import { AuthState } from 'src/app/store/app.reducers';
import * as AuthActions from 'src/app/store/auth.actions';

import { errorMessagesResponse } from 'src/app/utils/error-message-response';
import { TokenStorageService } from 'src/app/services/token-storage.service';


@Component({
  selector: 'vex-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,  //revisar si esto vale la pena usar
  animations: [
    fadeInUp400ms
  ],


})
export class LoginComponent implements OnInit {

  form: FormGroup;
  inputType = 'password';
  visible = false;
  loginFormErrors: any;




  constructor(private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private snackbar: MatSnackBar,
    private store: Store<AuthState>,
    public ts: TokenStorageService,
  ) {

    this.loginFormErrors = {
      email: {},
      password: {}
    };
  }

  ngOnInit() {


    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });

    this.form.valueChanges.subscribe(() => {
      this.onLoginFormValuesChanged();
    });

    this.ts.clear();
    //  this.userProfile = undefined;
    this.authService.setLoggedIn(false);

    this.authService.getIPAddress();
  }

  onLoginFormValuesChanged(): void {
    for (const field in this.loginFormErrors) {
      if (!this.loginFormErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.loginFormErrors[field] = {};

      // Get the control
      const control = this.form.get(field);

      if (control && control.dirty && !control.valid) {
        this.loginFormErrors[field] = control.errors;
      }
    }
  }


  onSubmit(value) {

    this.authService.authenticate(value)
      .subscribe({
        next: (res) => {
          switch (res.status_code) {
            case 200: {

              this.store.dispatch(new AuthActions.SignIn());
             // localStorage.setItem('token',res.access_token);
              this.store.dispatch(new AuthActions.SetToken(res.access_token));
             
              //set bearer token
              this.store.dispatch(new AuthActions.SignupSuccess(res));
              this.router.navigate(['/']);
              break;
            }
            case 422: {
              this.snackbar.open("Las credenciales no son correctas", 'Error', {
                duration: 10000
              });
            }
          }
        },
        error: (err) => {
          const messages: any = errorMessagesResponse(err);

          this.snackbar.open(messages, 'Existe Algunos errores', {
            duration: 10000
          });
        }
      });


  }





  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

}
