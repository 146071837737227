<form (ngSubmit)="save(form.value)" [formGroup]="form" autocomplete="off">
  <div class="flex items-center" mat-dialog-title>

    <h2 class="headline m-0 flex-auto">Cambio de Contraseña</h2>






    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>


  
  <mat-dialog-content class="flex flex-col">

    <div class="flex flex-col sm:flex-row">

      <mat-form-field class=" mt-6 flex-auto">
        <mat-label>Nueva Contraseña</mat-label>
        <mat-icon svgIcon="mat:key"  matPrefix></mat-icon>

        <input [type]="inputTypeP" formControlName="password" matInput required>
        <button (click)="toggleVisibilityP()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
          <mat-icon *ngIf="visibleP" svgIcon="mat:visibility"></mat-icon>
          <mat-icon *ngIf="!visibleP" svgIcon="mat:visibility_off"></mat-icon>
        </button>
        <mat-hint>Haga clic en el ojo para alternar la visibilidad</mat-hint>
        <mat-error *ngIf="form.get('password').hasError('required')">Necesitamos una contraseña nueva</mat-error>
      </mat-form-field>

      <mat-form-field class="sm:mt-6 sm:ml-6 flex-auto">
        <mat-label>Repetir Contraseña</mat-label>

        <input [type]="inputTypeR" formControlName="password_confirmation" matInput required>
        <button (click)="toggleVisibilityR()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
          <mat-icon *ngIf="visibleR" svgIcon="mat:visibility"></mat-icon>
          <mat-icon *ngIf="!visibleR" svgIcon="mat:visibility_off"></mat-icon>
        </button>
        <mat-hint>Haga clic en el ojo para alternar la visibilidad</mat-hint>
        <mat-error *ngIf="form.get('password_confirmation').hasError('required')">Repetir Contraseña
        </mat-error>

        <mat-icon svgIcon="mat:key"  matPrefix></mat-icon>
      </mat-form-field>
    </div>




  </mat-dialog-content>


  <mat-dialog-actions class="flex items-center  px-6 py-4">
    <div class=" w-1/6">

    </div>

    <div class="flex justify-end  w-5/6 ">
      <button mat-stroked-button mat-dialog-close type="button">CANCELAR</button>
      <button color="primary" mat-flat-button type="submit" [disabled]="form.invalid">SOLICITAR EL CAMBIO</button>
    </div>
  </mat-dialog-actions>
</form>