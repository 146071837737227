<div class="text-center py-24 header">
  <div class="container">
    <h2 @fadeInUp class="display-2 mt-16 mb-8">Hola, ¿Cómo podemos ayudar?</h2>
    <!--<div @fadeInUp
         class="border rounded-full bg-foreground py-2 ltr:pl-6 ltr:pr-3 rtl:pr-6 rtl:pl-3 text-secondary max-w-xl mx-auto border border-gray-200 flex items-center">
      <mat-icon class="flex-none" svgIcon="mat:search"></mat-icon>
      <input class="border-0 ltr:ml-4 rtl:mr-4 placeholder:text-secondary subheading-2 outline-none font-medium w-0 bg-foreground flex-auto"
             placeholder="Search for answers..."
             type="text">
      <button class="rounded-full flex-none" color="primary" mat-flat-button type="button">Search</button>
    </div>-->

    <div @fadeInUp
      class="mt-24 -mb-16 mx-auto  sm:flex-row gap-4 sm:gap-6 grid grid-cols-2 sm:grid-cols-5">
      <div *ngFor="let link of links; trackBy: trackByRoute" class="bg-foreground rounded-lg">
        <a #rla="routerLinkActive"
          [ngClass]="{ 'border-primary bg-primary/10': rla.isActive, 'hover:bg-app-bar border-gray-200 border-transparent': !rla.isActive }"
          [routerLink]="link.route"
          class="border  text-center p-6 trans-ease-out cursor-pointer relative text-dark flex flex-col items-center justify-center rounded-lg"
          matRipple routerLinkActive="">
          <mat-icon [class.text-hint]="!rla.isActive" [class.text-primary]="rla.isActive" [svgIcon]="link.icon"
            class="trans-ease-out icon-3xl"></mat-icon>
          <h3 class="title mb-0 mt-4">{{ link.label }}</h3>
        </a>
      </div>
    </div>

  </div>
</div>

<router-outlet></router-outlet>

<div class="bg-card py-16 px-gutter footer">
  <h2 class="display-1 mt-0 mb-4 text-center">¿Aún tienes preguntas?</h2>
  <!-- <h3 class="subheading-2 text-hint text-center max-w-lg mx-auto m-0">A wonderful serenity has taken possession of my
    entire
    soul, like these sweet
    mornings of spring which I enjoy with my whole heart.</h3> -->

  <div class="mt-16 max-w-3xl mx-auto grid grid-cols-1 sm:grid-cols-2 gap-6">
    <div class="text-center p-6 border rounded">
      <mat-icon class="text-hint icon-3xl" svgIcon="mat:phone_in_talk"></mat-icon>
      <h3 class="title mb-0 mt-4">+5959 (985) 714844</h3>
      <h4 class="subheading-2 m-0 text-hint">Llámenos en cualquier momento para una solución rápida</h4>
    </div>

    <div class="text-center p-6 border rounded">
      <mat-icon class="text-hint icon-3xl" svgIcon="mat:mail"></mat-icon>
      <h3 class="title mb-0 mt-4">odontocloudsoft@gmail.com</h3>
      <h4 class="subheading-2 m-0 text-hint">Envíenos un correo electrónico para resolver su problema.</h4>
    </div>
  </div>
</div>