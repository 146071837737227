<form (ngSubmit)="save(form.value)" [formGroup]="form" autocomplete="off">
  <div class="flex items-center"  mat-dialog-title>
      <div class="flex items-center">
        <div class="mr-2">
          <h2 lass="headline m-0 flex-auto" >Cambio de Clínica/Concultorio</h2>
          <p class="text-sm">Cambia la clínica/consultorio seleccionando la opción disponible y luego presione aplicar</p>
        </div>
      </div>
      <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>
  
  
    <mat-dialog-content class="flex flex-col">
  
      <div class="flex flex-col sm:flex-row">
  
        <mat-form-field class=" mt-6 flex-auto w-72">
          <mat-label>Escriba el nombre de usuario</mat-label>
          <mat-icon svgIcon="mat:groups"  matPrefix></mat-icon>
          <input type="text" formControlName="name" matInput required  [matAutocomplete]="auto">
          
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayClinic">
            <mat-option *ngFor="let p of clinics " [value]="p" class="custom-option">
  
              <div class=" w-full  flex items-center   ">
                <!-- <div class="relative flex items-center space-x-4">
                  <img
                    src="https://avatars2.githubusercontent.com/u/1490347?s=460&u=39d7a6b9bc030244e2c509119e5f64eabb2b1727&v=4"
                    alt="My profile" class="w-8 h-8 rounded-full">
                  <span class="absolute h-4 w-4 bg-green-400 rounded-full bottom-0 right-0 border-2 border-white"></span>
                </div> -->
                <div class="flex-grow p-3">
                  <div class="font-semibold text-gray-700">
                    Equipo: {{ p?.work_group?.name }} {{ p?.work_group?.type }}
                  </div>
                  <div class="text-sm text-gray-500">
                    Consultorio.{{ p?.work_group?.company?.name }} 
                  </div>
                </div>
                <!-- <div class="p-2">
                  <span class="block h-4 w-4 bg-blue-400 rounded-full bottom-0 right-0"></span>
                </div> -->
              </div>
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="form.get('name').hasError('required')">Necesitamos un nombre de usuario</mat-error>
        </mat-form-field>
  
      </div>
  
  

  
    </mat-dialog-content>


    <mat-dialog-actions class="flex items-center  px-6 py-4">
      <div class=" w-1/6">
  
      </div>
  
      <div class="flex justify-end  w-5/6 ">
        <button mat-stroked-button mat-dialog-close type="button">CANCELAR</button>
        <button color="primary" mat-flat-button type="submit" [disabled]="form.invalid">APLICAR</button>
      </div>
    </mat-dialog-actions>
  </form>
  