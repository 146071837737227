import { Professional } from "./professional.model";

interface Company {
  id: string;
  name: string;
}

interface WorkGroup {
  id: string;
  company_id: string;
  name: string;
  type: string;
  is_active: string;
  company: Company;
}

export class WGroupInUser {
  id: number;
  // ids
  users_id: string;
  work_group_id: string;
  role_user_id: string;
  is_activo_job: string;
  // data
  work_group: WorkGroup;

  public changeData(item): WGroupInUser {
    this.id = item.id;
    this.users_id = item.users_id;
    return this;
  }

}

export class Rol {
  id:number;
  name:string;


}


export class WorkGroupUser {
  id: number;
  // ids
  users_id: string;
  user: Professional;
  work_group_id: string;
  role_user_id: string;
  role: string;
  permissions:string[];

  is_active_job: string;
  is_delete: string;



}


export class WorkGroupPermission {
  id: number;
  // ids
  role: Rol;
  work_group_id: number;
  role_user_id: number;
  permissions:string[];

}


export enum Role {
  Manager = 'manager',
  Professional = 'professional',
  Admin = 'admin',
  Assistant = 'assistant',
  Employee = 'employee',
  Secretary = 'secretary'
}

export const RolesData = [
  {
      label: 'Gestor',
      value: 2,
      description: 'Puede configurar los datos de la clínica ademas todas las funcionalidades de un profesional.',
      icon:'mat:manage_accounts'
  },
  {
      label: 'Profesional',
      value: 4,
      description: 'Puede utilizar todas las funcionalidades que corresponden con las labores odontologías profesionales .',
      icon:'mat:medical_information'

  },
  {
      label: 'Asistente',
      value: 5,
      description: 'El es que asiste al profesional.',
      icon:'mat:people'
  },
  {
      label: 'Secretario/a',
      value: 7,
      description: 'Administra la agenda y algunas funcionalidades de odontología',
      icon:'mat:support_agent'
  },
  {
      label: 'Empleada/o',
      value: 6,
      description: 'Se encarga de realizar cargas de productos.',
      icon:'mat:cleaning_services'
      
  }
];