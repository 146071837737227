<form (ngSubmit)="save()" [formGroup]="form">
  <div class="mb-0 body-1" mat-dialog-title>
    <div>

      <h2 class="title m-0 flex items-center">
      
          <mat-icon [svgIcon]="'mat:'+noty.data.icon"  [ngClass]="noty.data.colorClass" class="notification-icon flex-none" ></mat-icon>

        <div class="px-4 py-5 sm:px-6  flex-grow  ">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{noty.data.name}}
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-400">
            {{ noty.created_at | relativeDateTime }}
          </p>
        </div>

        <button cdkFocusInitial class="text-secondary" mat-dialog-close mat-icon-button type="button">
          <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
      </h2>


    </div>


    <mat-divider class="-mx-6 mt-6"></mat-divider>
  </div>

  <mat-dialog-content class="pb-6 content">

    <h2 class="body-2 font-medium m-0 mt-6 text-secondary flex items-center">
      <mat-icon svgIcon="mat:description" class="ltr:mr-4 rtl:ml-4 flex-none" ></mat-icon>
      <span>Detalle</span>
    </h2>

    <div class="ltr:ml-10 rtl:mr-10 mt-3 flex flex-col">
      <mat-form-field appearance="outline"  class="vex-dense-form-field body-1">
        <textarea cdkAutosizeMinRows="2" formControlName="description" matInput  matTextareaAutosize
          placeholder="Add a description ..."></textarea>
      </mat-form-field>
    </div>




    <mat-divider class="-mx-6 mt-6 flex items-center"></mat-divider>
    <div *ngIf="this.noty.data.action">
      <h2 class="body-2 font-medium m-0 mt-6 text-secondary flex items-center">
        <mat-icon svgIcon="mat:insert_comment" class="ltr:mr-4 rtl:ml-4 flex-none" ></mat-icon>
        <span  class="flex-auto">Acción</span>
      </h2>

      <div class="ltr:ml-10 rtl:mr-10 mt-3 flex flex-col">
        
      </div>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cerrar</button>
    <button color="primary" mat-raised-button type="submit">Aceptar</button>
  </mat-dialog-actions>
</form>
