<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <div class="flex items-center">
      <div class="dropdown-heading-icon flex items-center justify-center">
        <!-- <mat-icon [icIcon]="icPerson"></mat-icon> -->
        <img class=" rounded-full" src="{{profile?.profile_photo_url}}">
      </div>
      <div class="dropdown-heading">
        <h2 class="font-medium"> {{profile?.fullName}}</h2>
        <p class="text-xs font-mono "> {{profile?.cell_phone}} - {{profile?.email}}</p>
      </div>
    </div>

    <button [matMenuTriggerFor]="settingsMenu" mat-icon-button matTooltip="Configuraciones de usuario" matTooltipPosition="before"
      type="button">
      <mat-icon class="notifications-header-icon" svgIcon="mat:settings"></mat-icon>
    </button>
  </div>

  <div class="dropdown-content">
    <a (click)="close()" *ngFor="let item of items; trackBy: trackById" [routerLink]="item.route"
      class="notification flex items-center" matRipple>
      <mat-icon [svgIcon]="item.icon" [ngClass]="item.colorClass" class="notification-icon flex-none"></mat-icon>
      <div class="flex-auto">
        <div class="notification-label">{{ item.label }}</div>
        <div class="notification-description">{{ item.description }}</div>
      </div>
      <mat-icon class="notification-chevron flex-none" svgIcon="mat:chevron_right"></mat-icon>
    </a>
  </div>

  <div class="dropdown-footer flex items-center justify-between">
    <button [matMenuTriggerFor]="statusMenu" class="dropdown-footer-select" mat-button type="button">
      <ng-container *ngFor="let status of statuses; trackBy: trackById">
        <span *ngIf="status === activeStatus">
          <mat-icon [ngClass]="status.colorClass" [svgIcon]="status.icon"></mat-icon>
          <span>{{ status.label }}</span>
          <mat-icon class="dropdown-footer-select-caret" svgIcon="mat:arrow_drop_down"></mat-icon>
        </span>
      </ng-container>
    </button>
    <button (click)="logout()" color="primary" mat-button>Cerrar Sesion</button>
  </div>
</div>

<mat-menu #statusMenu="matMenu" xPosition="before" yPosition="below">
  <button (click)="setStatus(status)" *ngFor="let status of statuses; trackBy: trackById" mat-menu-item>
    <mat-icon [ngClass]="status.colorClass" [svgIcon]="status.icon"></mat-icon>
    <span>{{ status.label }}</span>
  </button>
</mat-menu>


<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item (click)="changeEmail()">
    <mat-icon svgIcon="mat:email"></mat-icon>
    <span>Cambiar Correo</span>
  </button>

  <button mat-menu-item (click)="changeName()">
    <mat-icon svgIcon="mat:verified_user"></mat-icon>
    <span>Cambiar Usuario</span>
  </button>

  <button mat-menu-item (click)="changePass()">
    <mat-icon svgIcon="mat:lock"></mat-icon>
    <span>Cambiar Contraseña</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:notifications_off"></mat-icon>
    <span>Deshabilitar Notificaciones</span>
  </button>
</mat-menu>