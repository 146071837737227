<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <div>
      <div class="dropdown-heading">Notificaciones</div>
      <div class="dropdown-subheading">Tienes nuevas {{ notifications?.length }} Notificaciones.</div>
    </div>

    <button [matMenuTriggerFor]="settingsMenu" mat-icon-button type="button">
      <mat-icon svgIcon="mat:settings" class="notifications-header-icon"></mat-icon>
    </button>
  </div>

  <div class="dropdown-content">
    <a *ngFor="let notification of notifications; trackBy: trackById" [class.read]="notification.data.read"
      [routerLink]="[]" (click)="showNotification(notification)" class="notification flex items-center" matRipple>

      <mat-icon  [svgIcon]="'mat:'+notification.data?.icon"  [ngClass]="notification.data.colorClass"
        class="notification-icon flex-none"></mat-icon>
      <div class="flex-auto">
        <div class="notification-label">{{ notification.data.name }}</div>
        <div class="notification-description">{{ notification.created_at | relativeDateTime }}</div>
      </div>
      <mat-icon class="notification-chevron flex-none" svgIcon="mat:chevron_right"></mat-icon>
    </a>
    <a *ngIf="showNodata" [class.read]="true" [routerLink]="[]" class="notification flex items-center" matRipple>


      <mat-icon svgIcon="mat:check_circle" [ngClass]="'text-green'" class="notification-icon flex-none"></mat-icon>
      <div class="flex-auto">
        <div class="notification-label">No tienes mensajes por leer.</div>
        <div class="notification-description">Descanse un poco.</div>
      </div>
      <mat-icon class="notification-chevron flex-none" svgIcon="mat:chevron_right"></mat-icon>
    </a>
  </div>

  <div class="dropdown-footer flex items-center justify-center">
    <button color="primary" class="m-1" (click)="markAllNotification()" mat-button type="button">MARCAR TODO</button>
    <button color="secundary" class="m-1" mat-button type="button" (click)="close()"
      [routerLink]="['/notificaciones']">VER TODO</button>
  </div>
</div>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon svgIcon="mat:clear_all"></mat-icon>
    <span>Marcar todo como leido</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:clear_all"></mat-icon>
    <span>Disable Notifications</span>
  </button>
</mat-menu>