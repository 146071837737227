import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { CommonService } from 'src/app/services/common.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { format, } from 'date-fns';

import { Meeting } from 'src/app/models/meeting.model';
import { trackById } from 'src/@vex/utils/track-by';
interface dataStadistic {
  size_serve: number;
  media_size: number;
  d: number;
}



@Component({
  selector: 'vex-quickpanel',
  templateUrl: './quickpanel.component.html',
  styleUrls: ['./quickpanel.component.scss']
})
export class QuickpanelComponent implements OnInit {

  date = DateTime.local().toFormat('DD');
  dayName = DateTime.local().toFormat('EEEE');
  viewDate: Date = new Date();
  events: Meeting[] = null;
  statistics: dataStadistic;


  total = 0;
  used = 0;
  trackById = trackById;

  constructor(private cService: CommonService,
    private ts: TokenStorageService) { }

  ngOnInit() {
    this.loadCalendar();
    this.loadStatistics();
  }

  loadCalendar() {
    this.viewDate = new Date();


    // console.log('get s ' + format(this.viewDate, 'yyyy-MM-dd 07:00:00'));
    // console.log('get e ' + format(this.viewDate, 'yyyy-MM-dd 23:00:00'));

    const s = new Date(format(this.viewDate, 'yyyy-MM-dd 07:00:00'));
    const e = new Date(format(this.viewDate, 'yyyy-MM-dd 23:59:59'));

    this.ts.isGroupWorkNow().then((dataGroup) => {
      if (dataGroup == null) return;
      const query = '/' + dataGroup.is_group + '/s/' + s.getTime() + '/e/' + e.getTime();


      this.cService
        .getLoadQuery('/meeting-calendar', query)
        .subscribe({
          next: (res) => { this.events = res; },
          error: (err) => { this.cService.show(err, 'error'); }
        });

    });


  }


  loadStatistics() {

 
    this.ts.isGroupWorkNow().then((dataGroup) => {
      if (dataGroup == null) return;
      const query = '?is_group=' + dataGroup.is_group;
      this.cService
        .getLoadQuery('/dash-statistics', query)
        .subscribe({
          next: (res) => {
            this.statistics = res.data;
            this.updatedData();
          },
          error: (err) => { this.cService.show(err, 'error'); }
        });
    });





  }



  updatedData() {

    this.used = this.statistics?.media_size * this.statistics?.size_serve / 100;
  }

}
