<div class="body-2 p-6 bg-primary text-primary-contrast">
  <p>Hoy</p>
  <div class="display-1">{{ dayName }}</div>
  <div class="display-1">{{ date }}</div>
</div>

<mat-divider></mat-divider>
<div class="h-full flex flex-col  shadow-xl overflow-y-scroll">
  <div class="flex-1 py-4 overflow-y-auto px-3 sm:px-6">
    <div class="flex items-start justify-between">
      <h2 class="text-base font-medium text-gray-900" id="slide-over-title">
        Lista de actividades
      </h2>
      <div class="ml-3 h-7 flex items-center">
        <button mat-icon-button (click)="loadCalendar()" color="primary">
          <!-- <mat-icon  svgIcon="mat:refresh"   ></mat-icon> -->
        </button>
      </div>
    </div>

    <div class="mt-8">
      <div class="flow-root">
        <ul role="list" class="-my-6 divide-y divide-gray-200">
          <li class=" py-1 flex" *ngFor="let event of events; trackBy: trackById ">
            <div class="flex-shrink-0 w-14 h-14 border border-gray-200 rounded-md overflow-hidden">

              <ng-container>
                <img class="object-cover w-full h-full" width="54" height="54"
                  [src]="'https://ui-avatars.com/api/?name='+event?.patient?.first_name+'&color=7F9CF5&background=EBF4FF'"
                  alt="Contact avatar" />
              </ng-container>
            </div>

            <div class="ml-4 flex-1 flex flex-col">
              <div>
                <div class="flex justify-between text-base font-medium text-gray-900">
                  <p class="font-semibold">

                    {{event.patient.first_name}} {{event.patient.last_name}}

                  </p>
                  <p class="ml-4 text-xs  text-hint">
                    {{event.state}}
                  </p>
                </div>
                <p class="mt-1 text-xs font-semibold text-secondary">
                  {{event.reason}}
                </p>
              </div>
              <div class="flex-1 flex items-end justify-between text-sm">
                <p class="text-secondary ">
                  {{event.date_hour_start | date: 'HH:mm'}} - {{event.duration }} min.
                </p>

                <div class="flex">
                  <button type="button" class="font-medium text-indigo-600 hover:text-indigo-500">Avisar</button>
                </div>
              </div>
            </div>
          </li>
          <li class=" py-1 flex" *ngIf="events?.length==0">


            <div class="ml-4 flex-1 flex flex-col">
              <div>
                <div class="flex justify-between text-base font-medium text-gray-900">

                  <p class="ml-4 text-sm  text-hint">
                    No existe actividades para esta fecha.
                  </p>
                </div>

              </div>

            </div>
          </li>


        </ul>
      </div>
    </div>
  </div>


</div>



<mat-divider></mat-divider>
<div class="px-2 py-4">

  <h3 class="font-sans font-medium text-sm">ESTADISTICA DEL SERVIDOR</h3>
  <div class="list-item" matRipple>
    <p class="font-mono font-medium text-sm">Tamaño en disco ({{this.statistics?.media_size | number: '.0-0'}}MB /
      {{this.statistics?.size_serve}}MB)</p>
    <p class="progress-bar">
      <mat-progress-bar color="primary" mode="determinate" value="{{this.used}}"></mat-progress-bar>
    </p>
  </div>
</div>


<!-- <div class="vex-list-item" matRipple>
  <p>RAM Usage (6,175 MB / 16,384 MB)</p>
  <p class="progress-bar">
    <mat-progress-bar [value]="34" color="accent" mode="determinate"></mat-progress-bar>
  </p>
</div>

<div class="vex-list-item" matRipple>
  <p>CPU Temp (43° / 80°)</p>
  <p class="progress-bar">
    <mat-progress-bar [value]="54" color="warn" mode="determinate"></mat-progress-bar>
  </p>
</div> -->