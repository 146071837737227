import { Component, OnInit } from '@angular/core';



import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { Link } from 'src/@vex/interfaces/link.interface';
import { trackByRoute } from 'src/@vex/utils/track-by';


@Component({
  selector: 'vex-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss'],
  animations: [
    stagger40ms,
    fadeInUp400ms
  ]
})
export class HelpCenterComponent implements OnInit {


  links: (Link & { icon: string })[] = [
    {
      label: 'Primeros Pasos',
      route: 'primeros-pasos',
      icon: 'mat:flag'
    },
    {
      label: 'Precios y planes',
      route: 'precios',
      icon: 'mat:attach_money'
    },
    {
      label: 'FAQ',
      route: 'faq',
      icon: 'mat:contact_support'
    },
    {
      label: 'Guías',
      route: 'guias',
      icon: 'mat:book'
    },
    {
      label: 'Retroalimentacion',
      route: 'retroalimentacion',
      icon: 'mat:feedback'
    }
  ];

  trackByRoute = trackByRoute;

  constructor() { }

  ngOnInit() {
  }

}
