<form (ngSubmit)="save(form.value)" [formGroup]="form" autocomplete="off">




  <mat-dialog-content>

    <div class="p-4">
      <h2 class="text-2xl font-semibold mb-4">Danos tu opinión</h2>
        <div class="mb-4">
          <label for="feedback-type" class="block text-sm font-medium text-gray-700 mb-2">Tipo de retroalimentación:</label>

          <mat-radio-group aria-label="Select an option" class=" flex flex-col justify-evenly  "
            formControlName="type">

            <mat-radio-button value="like">
              <div class="flex items-stretch  gap-1 text-green-500">
                <div class="flex">
                  <mat-icon svgIcon="mat:sentiment_very_satisfied" > </mat-icon>
                </div>
                <div>
                  Tu buena vibra nos motiva. 
                </div>
              </div>
            </mat-radio-button>
            <mat-radio-button value="dislike">
              <div class="flex items-center gap-1 text-red-500">
                <div class="flex">
                  <mat-icon svgIcon="mat:sentiment_dissatisfied"></mat-icon>
                </div>
                <div>
                  En qué debemos mejorar.
                </div>
              </div>
            </mat-radio-button>
            <mat-radio-button value="suggestion">
              <div class="flex items-center gap-1 text-blue-500">
                <div class="flex">
                  <mat-icon svgIcon="mat:sentiment_satisfied"></mat-icon>
                </div>
                <div>
                  Escuchamos tus sugerencias
                </div>
              </div>
            </mat-radio-button>
          </mat-radio-group>



        </div>
        <div class="mb-4">
          <label for="message" class="block text-sm font-medium text-gray-700 mb-2">Observación:</label>
          <mat-form-field class="w-full">
            <mat-label>Haz tu observación</mat-label>
            <textarea formControlName="message" matInput></textarea>
          </mat-form-field>

        </div>
    </div>



  </mat-dialog-content>



  <mat-dialog-actions class="flex items-center  px-6 pb-4"  >
    <div class=" w-2/5">
     
    </div>
  
    <div class="flex justify-end  w-3/5 " >
      <button mat-stroked-button mat-dialog-close type="button">CERRAR</button>
      <button *ngIf="isCreateMode()"  class="bg-green-500 hover:bg-green-600 text-white " mat-button type="submit"  [disabled]="form.invalid">Enviar tu opinión</button>
      <button *ngIf="isUpdateMode()" color="primary"  mat-button type="submit"
        [disabled]="form.invalid">ACTUALIZAR</button>
    </div>
  </mat-dialog-actions>
</form>