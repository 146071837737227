<form (ngSubmit)="save(form.value)" [formGroup]="form" autocomplete="off">
  <div class="flex items-center" mat-dialog-title>

    <h2 class="headline m-0 flex-auto">Cambio de Usuario</h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>



  <mat-dialog-content class="flex flex-col">

    <div class="flex flex-col sm:flex-row">

      <mat-form-field class=" mt-6 flex-auto w-72">
        <mat-label>Escriba el nombre de usuario</mat-label>
        <mat-icon svgIcon="mat:account_circle"  matPrefix></mat-icon>

        <input type="text" formControlName="name" matInput required>


        <mat-error *ngIf="form.get('name').hasError('required')">Necesitamos un nombre de usuario</mat-error>
      </mat-form-field>

      <div class="sm:mt-6 sm:ml-6 flex-auto p-4">
        <mat-icon svgIcon="mat:verified_user" [class]="icColorVerified" matPrefix></mat-icon>
      </div>
    </div>




  </mat-dialog-content>




  <mat-dialog-actions class="flex items-center  px-6 py-4">
    <div class=" w-1/6">

    </div>

    <div class="flex justify-end  w-5/6 ">
      <button mat-stroked-button mat-dialog-close type="button">CANCELAR</button>
      <button color="primary" mat-flat-button type="submit" [disabled]="form.invalid">SOLICITAR EL CAMBIO</button>
    </div>
  </mat-dialog-actions>
</form>