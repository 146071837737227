import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Feedback } from 'src/app/models/feedback';
import { CommonService } from 'src/app/services/common.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'vex-feebback-form',
  templateUrl: './feebback-form.component.html',
  styleUrls: ['./feebback-form.component.scss']
})
export class FeebbackFormComponent implements OnInit {

  static id = 100;
  private baseUri: string = '/feedback';
  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    @Inject(LOCALE_ID) private locale: string,
    private dialogRef: MatDialogRef<FeebbackFormComponent>,
    private fb: FormBuilder,
    private cService: CommonService,
    private ts: TokenStorageService,
    private dialog: MatDialog) {
  }

  ngOnInit() {
    if (this.defaults) {
      this.mode = 'update';
    } else {
      this.defaults = {} as Feedback;
    }




    this.form = this.fb.group({
      id: [this.defaults.id || ''],
      message: [this.defaults.message || '', [Validators.required]],
      type: [this.defaults.type || '', [Validators.required]],
      user_info: this.defaults.user_info || '',
      user_id: this.defaults.city || '',
    });
  }

  save(valueForm) {
    if (this.mode === 'create') {
      this.createPatient(valueForm);
    } else if (this.mode === 'update') {
      this.updatePatient(valueForm);
    }
  }

  createPatient(valueForm) {

    this.ts.isGroupWorkNow().then((dataGroup) => {
      valueForm.is_group = dataGroup.is_group;
      valueForm.is_created = dataGroup.is_user;
      valueForm.user_id = dataGroup.is_user;
      valueForm.is_active = 1;

      this.cService.create(this.baseUri, valueForm)
        .subscribe({
          next: (res) => {
            switch (res.status_code) {
              case 200: {
                this.cService.show('Gracias por tu devolución. Tu opinión es importante.  ', 'success');
                this.dialogRef.close(res.data);
                break;
              }
              default:
                this.cService.show('Estamos experimentando algunos problema.  ', 'warning');
            }
          },
          error: (err) => { this.cService.show(err, 'error'); }
        });
    });

  }

  updatePatient(valueForm) {  
  
    this.ts.isGroupWorkNow().then((dataGroup) => {
      valueForm.is_group = dataGroup.is_group;
      valueForm.is_updated = dataGroup.is_user;
      valueForm.is_active = 1;

      this.cService.update(this.baseUri, valueForm.id, valueForm)
        .subscribe({
          next: (res) => {
            switch (res.status_code) {
              case 200: {
                this.cService.show('Se actualizado correctamente ', 'success');
                this.dialogRef.close(res.data);
                break;
              }
            }
          },
          error: (err) => { this.cService.show(err, 'error'); }
        });
    });


  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }


}
