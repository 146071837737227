import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TokenStorageService } from './services/token-storage.service';
import { AuthStateService } from './services/auth-state.service';
@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate, CanActivateChild {

  constructor(private route: Router, private authStateService: AuthStateService, public ts: TokenStorageService) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      const isAuthenticated = await this.ts.getAccessToken() !== null;
      if (isAuthenticated) {
        if (next.data.roles && next.data.roles.indexOf(await this.ts.isRoles()) === -1) {
          //console.log("canActivie no permitido " + this.ts.isRoles());
          // role not authorised so redirect to home page
          this.route.navigate(['/forbidden']);
          return false;
        }
        return true;
      } else {
        // Redirigir a la página de inicio de sesión si el usuario no está autenticado
        this.route.navigate(['/login']);
        return false;
      }

    // return this.store.select('authState').pipe(
    //   map((a: State) => {
    //    // console.log(a.authonticated)
    //     if (a.authonticated == false) {
    //       //!tendria que revisar para limpiar 
    //       this.route.navigate(['/login']);
    //     }
    //     //console.log("canActivie " + this.ts.isRoles() +" "+next.data.roles);
    //     if (next.data.roles && next.data.roles.indexOf(this.ts.isRoles()) === -1) {
    //       //console.log("canActivie no permitido " + this.ts.isRoles());
    //       // role not authorised so redirect to home page
    //       this.route.navigate(['/forbidden']);
    //       return false;
    //     }
    //     //console.log("canActivie ok " + this.ts.isRoles());

    //     return a.authonticated;
    //   })
    // );

  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }
}