<div class="vex-user-menu">
  <a (click)="close()"
     [routerLink]="['/ajustes']"
     class="vex-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:account_box"></mat-icon>
    <div class="vex-user-menu-item__label">Mi Clínica</div>
  </a>

  <!-- <a (click)="close()"
     [routerLink]="['/']"
     class="vex-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:insights"></mat-icon>
    <div class="vex-user-menu-item__label">Analytics</div>
    <div class="vex-user-menu-item__badge">NEW</div>
  </a>

  <a (click)="close()"
     [routerLink]="['/apps/social/timeline']"
     class="vex-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:settings"></mat-icon>
    <div class="vex-user-menu-item__label">Account Settings</div>
  </a> -->

  <div class="border-b border-divider mx-4"></div>

  <div class="vex-user-menu-item" matRipple matRippleColor="rgb(var(--color-primary), 0.1)" (click)="changeClinic()">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:compare_arrows"></mat-icon>
    <div class="vex-user-menu-item__label">Cambiar de Clínica/Consultorio</div>
    <!-- <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:chevron_right"></mat-icon> -->
  </div>

  <a (click)="close()"
    
     class="vex-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:person_off"></mat-icon>
    <div class="vex-user-menu-item__label">Darme de Baja</div>
  </a>
</div>
