import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';


import { PopoverRef } from '../../../../components/popover/popover-ref';
import { Professional } from 'src/app/models/professional.model';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { ProfilePassComponent } from 'src/app/pages/profile/profile-pass/profile-pass.component';
import { ProfileUserComponent } from 'src/app/pages/profile/profile-user/profile-user.component';
import { ProfileEmailComponent } from 'src/app/pages/profile/profile-email/profile-email.component';
import { AuthService } from 'src/app/services/auth.service';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {

  profile: Professional;
  items: MenuItem[] = [
    {
      id: '1',
      icon: 'mat:account_circle',
      label: 'Mi perfil',
      description: 'Datos personales',
      colorClass: 'text-teal',
      route: '/perfil'
    },
    // {
    //   id: '2',
    //   icon: icMoveToInbox,
    //   label: 'My Inbox',
    //   description: 'Messages & Latest News',
    //   colorClass: 'text-primary',
    //   route: '/apps/chat'
    // },
    // {
    //   id: '3',
    //   icon: icListAlt,
    //   label: 'My Projects',
    //   description: 'Tasks & Active Projects',
    //   colorClass: 'text-amber',
    //   route: '/apps/scrumboard'
    // },
    {
      id: '4',
      icon: 'mat:table_chart',
      label: 'Información de Pagos',
      description: 'Precios y Plan actual',
      colorClass: 'text-purple',
      route: '/pagos'
    }
  ];

  statuses: OnlineStatus[] = [
    {
      id: 'online',
      label: 'Online',
      icon: 'mat:check_circle',
      colorClass: 'text-green'
    },
    {
      id: 'away',
      label: 'Away',
      icon: 'mat:access_time',
      colorClass: 'text-orange'
    },
    {
      id: 'dnd',
      label: 'Do not disturb',
      icon: 'mat:do_not_disturb',
      colorClass: 'text-red-500'
    },
    {
      id: 'offline',
      label: 'Offline',
      icon: 'mat:offline_bolt',
      colorClass: 'text-gray-500'
    }
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  trackById = trackById;


  constructor(private cd: ChangeDetectorRef,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
    private ts: TokenStorageService,
    private auth: AuthService,
    private dialog: MatDialog) { }

  async ngOnInit() {
    try {
      const data = await this.ts.getProfile();
      //console.log(data);
      this.profile = new Professional(JSON.parse(data));
      this.cd.detectChanges();
      //console.log('Profile updated:', this.profile);
    } catch (error) {
      console.error('Error retrieving profile:', error);
    }
  }

  setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  close() {
    this.popoverRef.close();
  }

  logout() {
    this.auth.logout();
    this.popoverRef.close();
  }
  /**
   * Change of pass the users
   */
  changePass() {
    this.dialog.open(ProfilePassComponent, {
      // minWidth: '700px',
      maxWidth: '100%',
    }).afterClosed().subscribe((result) => {

      // if (result) {
      //   /**
      //    * Here we are updating our local array.
      //    * You would probably make an HTTP request here.
      //    */
      //   console.log(result);

      // }
    });
  }
  /**
   * Change of name user
   */
  changeName() {
    this.dialog.open(ProfileUserComponent, {
      // minWidth: '700px',
      maxWidth: '100%',
    }).afterClosed().subscribe((result) => {

      // if (result) {
      //   /**
      //    * Here we are updating our local array.
      //    * You would probably make an HTTP request here.
      //    */
      //   console.log(result);

      // }
    });
  }


  changeEmail() {
    this.dialog.open(ProfileEmailComponent, {
      // minWidth: '700px',
      maxWidth: '100%',
    }).afterClosed().subscribe((result) => {

      // if (result) {
      //   /**
      //    * Here we are updating our local array.
      //    * You would probably make an HTTP request here.
      //    */
      //   console.log(result);

      // }
    });
  }
}
