import { Injectable } from '@angular/core';
import { Card } from '../models/card.model';
import { BehaviorSubject } from 'rxjs';
import { MedicationLoad } from '../models/medication-load.model';
import { Generic } from '../models/generic.model';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  private dataSourceCard = new BehaviorSubject<Card>(null);
  dataCurrentCard = this.dataSourceCard.asObservable();
  
  private dataSourceMedication = new BehaviorSubject<MedicationLoad>(null);
  dataCurrentMedication = this.dataSourceMedication.asObservable();

  private dataSourceGeneric = new BehaviorSubject<Generic>(null);
  dataCurrentGeneric= this.dataSourceGeneric.asObservable();


  
  constructor() { }

  changeDataCard(data: Card) {
    this.dataSourceCard.next(data)
  }

  changeDataMedication(data: MedicationLoad) {
    this.dataSourceMedication.next(data)
  }
  

  changeDataGeneric(data: Generic) {
    this.dataSourceGeneric.next(data)
  }

  // setOption(option, value:any) {
  //   this.data=value;
  // }

  // getOption() {
  //   return this.data;
  // }

  // getData(option) {
  //   return this.data[option];
  // }
}
