import { DateTime } from 'luxon';



export interface Notification {
  id: string;
  icon: string;
  label: string;
  name: string;
  description: string;
  colorClass: string;
  datetime: DateTime;
  read?: boolean;
  table: string;
  action: string;
}


export class NotificationGeneric {
    id: string;
    data: Notification;
    

    created_at: DateTime;
    read_at?: DateTime;

    constructor(p) {
        this.id = p.id;
        this.data = p.data;
        this.created_at = p.created_at;
        this.read_at = p.read_at;
    }
  
    get name() {
      let name = '';
  
      if (this.data.name) {
        name = this.data.name;
      }
  
      return name;
    }
  
    set name(value) {
    }
  
    get icon() {
      let name:string ;
  
      if (this.data.icon) {
        name = this.data.icon;
      }
  
      return name;
    }
  
    set icon(value) {
    }
  
  
  
  
  }
  