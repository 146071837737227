import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Notification } from '../interfaces/notification.interface';
import { DateTime } from 'luxon';
import { trackById } from '../../../../utils/track-by';
import { NotificationGeneric } from 'src/app/models/notification.model';
import { MatDialog } from '@angular/material/dialog';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { PopoverRef } from 'src/@vex/components/popover/popover-ref';
import { CommonService } from 'src/app/services/common.service';
import { NotificationViewComponent } from 'src/app/pages/notification/notification-view/notification-view.component';

@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit {

  notifications: NotificationGeneric[]; //nocambiar el nombre de variable
  showNodata = true;


  trackById = trackById;

  constructor(private popoverRef: PopoverRef<ToolbarNotificationsDropdownComponent>, private cd: ChangeDetectorRef, private dialog: MatDialog, private cService: CommonService,
    private ts: TokenStorageService) {


  }

  ngOnInit() {


    this.ts.isDataProfile().then((profile) => {
      this.cService.getLIstSimple('/notification-unread/' + profile.id)
        .subscribe({
          next: (res) => {
            this.notifications = res.data;
            if (this.notifications.length > 0) {
              // this.popoverRef.clickCurrent(true);
              this.showNodata = false;
              this.cd.markForCheck();

            } else {
              //this.popoverRef.clickCurrent(false);
              this.cd.markForCheck();

            }
          },
          error: (err) => { this.cService.show(err, 'error'); }
        });
    });



  }



  showNotification(item) {
    this.notifications[this.notifications.findIndex(n => n.id = item.id)].data.read = true;

    this.dialog.open(NotificationViewComponent, {
      data: item,
      width: '700px',
      maxWidth: '100%',
      disableClose: true,
    }).afterClosed().subscribe(updatedMedication => {
      this.close();
      /**
       * Medication is the updated patient (if the user pressed Save - otherwise it's null)
       */
      if (updatedMedication) {


        /**
         * Here we are updating our local array.
         * You would probably make an HTTP request here.
         */
        // const index = this.dataSource.data.findIndex((existingMedication) => existingMedication.id === updatedMedication.id);
        // this.dataSource.data[index] = new Medication(updatedMedication);
        // this.dataSource.data = this.dataSource.data.slice();
        // this.subject$.next(this.patients);
      }
    });
  }

  close() {
    this.popoverRef.close(this);
  }

  markAllNotification() {

    this.ts.isDataProfile().then((profile) => {
      this.cService.update('/notification-mark-all', profile.id, [])
        .subscribe({
          next: (res) => {
            this.notifications = res.data;
            this.notifications.map(d => d.data.read = true);
            this.cService.show("Se ha marcado todos los mensajes 'como leídos'", 'success');
            this.close();
          },
          error: (err) => { this.cService.show(err, 'error'); }
        });
    });


  }

}
