import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangeClinicComponent } from 'src/app/pages/settings/change-clinic/change-clinic.component';
import { PopoverRef } from '../popover/popover-ref';

@Component({
  selector: 'vex-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  constructor(private readonly popoverRef: PopoverRef,private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  close(): void {
    /** Wait for animation to complete and then close */
    setTimeout(() => this.popoverRef.close(), 250);
  }


     
  changeClinic() {
    this.dialog.open(ChangeClinicComponent, {
      minWidth: '360px',
      maxWidth: '100%',
    }).afterClosed().subscribe((result) => {

  
    });
  }
}
