import { ChangeDetectorRef, Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';




import { CommonService } from 'src/app/services/common.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

import { WorkGroup } from 'src/app/models/company.model';


@Component({
  selector: 'vex-change-clinic',
  templateUrl: './change-clinic.component.html',
  styleUrls: ['./change-clinic.component.scss']
})
export class ChangeClinicComponent implements OnInit {

  clinics: any[]; //definir una clase
  private baseUri: string = '/profile-name';
  form: FormGroup;
  searchCtrl = new FormControl();







  constructor(
    @Inject(LOCALE_ID) private locale: string,


    private dialogRef: MatDialogRef<ChangeClinicComponent>,
    private fb: FormBuilder,
    private cService: CommonService,
    private ts: TokenStorageService) {
  }

  ngOnInit() {



    this.ts.getWorkGroup().then((data) => {
      this.clinics = JSON.parse(data);
    });

    // console.log("ventana clínica change ");
    // console.log(this.clinics);

    this.form = this.fb.group({

      name: [null || '', [Validators.required]],


    });





  }



  displayClinic(p) {
    return p ? p?.work_group?.name + " " + p?.work_group?.type + " " + p?.work_group?.company?.name : "";
  }



  save(valueForm) {
    //console.log(valueForm);
    this.ts.setWorkGroupNow(JSON.stringify(valueForm.name));

    this.cService.show("Actualizando cambio.", 'info');
    window.location.reload();
  }



}