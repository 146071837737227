import { AriaLivePoliteness } from '@angular/cdk/a11y';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NotifierComponent } from '../utils/notifier/notifier.component';

@Injectable({
  providedIn: 'root'
})
export class NotifierService {

 
  private horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  private verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  private politeness?: AriaLivePoliteness = 'off';


  constructor(private snackBar: MatSnackBar) { }

  showNotification(displayMessage: string, buttonText: string, typeMess: 'success' | 'error' | 'info' | 'warning') {
    this.snackBar.openFromComponent(NotifierComponent, {
      data: {
        message: displayMessage,
        buttonText: buttonText,
        type: typeMess
      },
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: typeMess
    });
  }

   /**
   * 
   * 
   * @param {string} message mensaje que se quiere visualizar
   * @param {string} typeMess tipo: ['error','success','info','warning']
   * @param {number} [duration] duracción en milisegundos 
   * @memberof MessageToast
   */
    show(message: string, typeMess: 'success' | 'error' | 'info' | 'warning', duration?: number): MatSnackBar {
      const config = new MatSnackBarConfig();
      let d = 10000;
      if (duration > 0) {
        d = duration;
      }
      config.verticalPosition = this.verticalPosition;
      config.horizontalPosition = this.horizontalPosition;
      config.duration = d;
      config.panelClass = [typeMess];
  
      this.snackBar.open(message, null, config);
      return this.snackBar;
    }
}
