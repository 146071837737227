import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { AES, MD5, enc } from 'crypto-js';

export interface DataGroup {
  is_group: number;
  is_user: number;
  company_id: number;
}

export interface WorkGroup {
  company_id: number;
}

export interface DataCompany {
  id: string;
}

export interface Profile {
  degree: string;
  specialty: string;
  id: number;
  professional_register: string;
}

interface EncryptedToken {
  key: string;
  value: string;
}


class ConnectionDB extends Dexie {
  tokens!: Dexie.Table<EncryptedToken, string>; // Añade esta línea

  constructor() {
    super('OdontoDB');

    this.version(1).stores({
      tokens:  'key,value'
    });

    // Asigna las tablas a las propiedades específicas
    this.tokens = this.table('tokens'); // Añade esta línea
  }
}

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  private db: ConnectionDB;

  constructor() {
    this.db = new ConnectionDB();
    this.db.on('versionchange', () => {
      // Cierra la conexión existente y avisa al usuario
      this.db.close();
      alert('La aplicación necesita recargarse debido a una actualización.');
    });

    //this.openDatabase();
  }

  // private openDatabase(): Promise<void> {
  //   return this.db.open().then(() => {
  //     console.log('Base de datos abierta correctamente.');
  //   }).catch((error) => {
  //     console.error('Error al abrir la base de datos:', error);
  //   });
  // }


  protected ACCESS_TOKEN = 'token';
  protected REFRESH_TOKEN = 'refreshToken';
  protected EXPERRIRES_AT = 'expiresAt';
  protected PROFILE = 'profile';
  protected WORKGROUPS = 'workgroups';
  protected WORKGROUPSNOW = 'workgroupsNow';
  private fixedText = 'TextoFijoSecreto';



  private generateKey(): string {
    const accessToken = this.getAccessToken();
    const key = (accessToken + MD5(this.fixedText)).toString();
    return key;
  }

  private encrypt(data: any): string {
    const key = this.generateKey();
    const encryptedData = AES.encrypt(JSON.stringify(data), key).toString();
    return encryptedData;
  }

  private decrypt(encryptedData: string): any {
    const key = this.generateKey();
    const decryptedBytes = AES.decrypt(encryptedData, key);
    const decryptedData = JSON.parse(decryptedBytes.toString(enc.Utf8));
    return decryptedData;
  }

  public async getAccessToken(): Promise<string> {
   // await this.openDatabase(); // Asegúrate de que la base de datos esté abierta
    const token = await this.db.tokens.get(this.ACCESS_TOKEN);
    // console.log(token) //!revisar
    return token?.value || null;
  }

  public setAccessToken(token: string): void {
    console.error(token)
    this.db.tokens.put({ key: this.ACCESS_TOKEN, value: token });
    // this.openDatabase().then(() => {
    //   this.db.tokens.put({ key: this.ACCESS_TOKEN, value: token });
    // });

  }

  public async getRefreshToken(): Promise<string> {
   // await this.openDatabase();
    const token = await this.db.tokens.get(this.REFRESH_TOKEN);
    return token?.value || null;
  }

  public setRefreshToken(token: string): void {
    this.db.tokens.put({ key: this.REFRESH_TOKEN, value: token });
    // this.openDatabase().then(() => {
    //   this.db.tokens.put({ key: this.REFRESH_TOKEN, value: token });
    // });
  }

  public async getExpiresAt(): Promise<string> {
    // await this.openDatabase();
    const expiresAt = await this.db.tokens.get(this.EXPERRIRES_AT);
    return expiresAt?.value || null;
  }

  public setExpiresAt(expiresAt: string): void {
    this.db.tokens.put({ key: this.EXPERRIRES_AT, value: expiresAt });
    // this.openDatabase().then(() => {
    //   this.db.tokens.put({ key: this.EXPERRIRES_AT, value: expiresAt });
    // });
  }

  public async getProfile(): Promise<string> {
    //await this.openDatabase();
    const encryptedProfile = await this.db.tokens.get(this.PROFILE);
    return encryptedProfile ? this.decrypt(encryptedProfile.value) : null;
  }

  public setProfile(profile: any): void {
    const encryptedProfile = this.encrypt(profile);
    this.db.tokens.put({ key: this.PROFILE, value: encryptedProfile });
    // this.openDatabase().then(() => {
    //   const encryptedProfile = this.encrypt(profile);
    //   this.db.tokens.put({ key: this.PROFILE, value: encryptedProfile });
    // });

  }

  public async getWorkGroup(): Promise<string> {
   // await this.openDatabase();
    const encryptedWorkGroup = await this.db.tokens.get(this.WORKGROUPS);
    return encryptedWorkGroup ? this.decrypt(encryptedWorkGroup.value) : null;
  }

  public setWorkGroup(workGroup: any): void {
    const encryptedWorkGroup = this.encrypt(workGroup);
    this.db.tokens.put({ key: this.WORKGROUPS, value: encryptedWorkGroup });
    // this.openDatabase().then(() => {
    //   const encryptedWorkGroup = this.encrypt(workGroup);
    //   this.db.tokens.put({ key: this.WORKGROUPS, value: encryptedWorkGroup });
    // });
  }

  public async getWorkGroupNow(): Promise<string> {
   // await this.openDatabase();
    const encryptedWorkGroupNow = await this.db.tokens.get(this.WORKGROUPSNOW);
    const decryptedData = encryptedWorkGroupNow ? this.decrypt(encryptedWorkGroupNow.value) : null;
    
    // console.log("getWorkNow");
    // console.log(decryptedData);

    if (decryptedData !== '' && decryptedData !== '""') {
      return decryptedData;
    }
    return null;
  }

  public setWorkGroupNow(workGroupNow: any): void {
    const encryptedWorkGroupNow = this.encrypt(workGroupNow);
    this.db.tokens.put({ key: this.WORKGROUPSNOW, value: encryptedWorkGroupNow });

    // this.openDatabase().then(() => {
    //   const encryptedWorkGroupNow = this.encrypt(workGroupNow);
    //   this.db.tokens.put({ key: this.WORKGROUPSNOW, value: encryptedWorkGroupNow });
    // });
  }

  // Resto del código...
  public async isGroupWorkNow(): Promise<DataGroup | null> {
  //  await this.openDatabase();
    const decryptedData = await this.getWorkGroupNow();
  
    if (!decryptedData) {
      return null;
    }
  
    const data = JSON.parse(decryptedData);
  
    const res: DataGroup = {
      is_group: data.work_group_id,
      is_user: data.users_id,
      company_id: data.work_group.company_id
    };
  
    return res;
  }
  
  public async isCompanyIdentify(): Promise<DataCompany | null> {
   // await this.openDatabase();
    const decryptedData = await this.getWorkGroupNow();
  
    if (!decryptedData) {
      return null;
    }
  
    const data = JSON.parse(decryptedData);
    const res: DataCompany = {
      id: data.work_group.company_id
    };
  
    return res;
  }
  
  public async isRoles(): Promise<string> {
   // await this.openDatabase();
    const decryptedData = await this.getWorkGroupNow();
    const data = decryptedData ? JSON.parse(decryptedData) : null;
  
    let res: string = 'invite';
    if (data !== null) {
      res = data.role;
    }
  
    return res;
  }
  
  public async checkIsRole(id: number): Promise<boolean> {
   // await this.openDatabase();
    const decryptedData = await this.getWorkGroupNow();
    const data = decryptedData ? JSON.parse(decryptedData) : null;
  
    return data?.role_user_id === id || false;
  }
  
  public async isPermissions(): Promise<string[]> {
    //await this.openDatabase();
    const decryptedData = await this.getWorkGroupNow();
    const data = decryptedData ? JSON.parse(decryptedData) : { permissions: ['invite'] };
  
    if (data.permissions && Array.isArray(data.permissions)) {
      return data.permissions;
    } else {
      return ['invite'];
    }
  }
  
  public async isDataProfile(): Promise<Profile | null> {
  //  await this.openDatabase();
    const decryptedData = await this.getProfile();
    const data = decryptedData ? JSON.parse(decryptedData) : null;
  
    if (!data) {
      return null;
    }
  
    const res: Profile = {
      professional_register: data.professional_register,
      degree: data.degree,
      id: data.id,
      specialty: data.specialty
    };
  
    return res;
  }
  
  

  public async clear(): Promise<void> {
 //   await this.openDatabase();
    await this.db.tokens.clear();
  }
}

