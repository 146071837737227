export class Professional {
  id: number;
  name : string;
  first_name: string;
  last_name: string;
  type_document: string;
  document: string;
  gender: string;
  birth_date: string;
  email: string;
  address: string;
  cell_phone: string;
  phone: string;
  imageSrc: string;
  blood_group: string;
  date_admission: string;

  professional_register: string;
  degree: string;
  specialty: string;
  bio:string

  city: string;
  country: string;

  
 
  // extra
  full_name: string;
  profile_photo_url: string;
  


  constructor(p) {
    this.id = p.id;
    this.name = p.name;
    this.imageSrc = p.imageSrc;
    this.first_name = p.first_name;
    this.last_name = p.last_name;
    this.document = p.document;
    this.type_document = p.type_document;
    this.gender = p.gender;
    this.birth_date = p.birth_date
    this.email = p.email;
    this.address = p.address;
    this.phone = p.phone;
    this.cell_phone = p.cell_phone;
    this.blood_group = p.blood_group;
    this.date_admission = p.date_admission;

    this.city = p.city;
    this.country = p.country;

    this.degree = p.degree;
    this.professional_register = p.professional_register;
    this.specialty = p.specialty;
    this.bio = p.bio;

    this.profile_photo_url = p.profile_photo_url;
    this.full_name = p.full_name;

  }

  // get name() {
  //   let name = '';

  //   if (this.first_name && this.last_name) {
  //     name = this.first_name + ' ' + this.last_name;
  //   } else if (this.first_name) {
  //     name = this.first_name;
  //   } else if (this.last_name) {
  //     name = this.last_name;
  //   }

  //   return name;
  // }

  // set name(value) {
  // }

  get fullName() {
    let name = '';
    if (this.first_name && this.last_name) {
      name = this.first_name + ' ' + this.last_name;
    } else if (this.first_name) {
      name = this.first_name;
    } else if (this.last_name) {
      name = this.last_name;
    }

    return name;

  }


  get documents() {
    return `${this.type_document} ${this.document}`;
  }

  set documents(value) {
  }

}
