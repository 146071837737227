import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { fadeInUp400ms } from '../../../../@vex/animations/fade-in-up.animation';

import { stagger80ms } from 'src/@vex/animations/stagger.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { debounceTime, distinctUntilChanged, switchMap, timestamp } from 'rxjs/operators';
import { CollectionsFormatData } from 'src/app/models/collections-format-data';
import { of } from 'rxjs';
import { Company } from 'src/app/models/company.model';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { RepeatValidator } from 'src/app/providers/RepeatValidator';
import { CommonService } from 'src/app/services/common.service';
import { trackById } from 'src/@vex/utils/track-by';
import { Router } from '@angular/router';


@Component({
  selector: 'vex-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    stagger80ms,
    fadeInUp400ms,
    scaleIn400ms,
    fadeInRight400ms
  ]
})
export class RegisterComponent implements OnInit {



  verticalAccountFormGroup: FormGroup;
  verticalPasswordFormGroup: FormGroup;
  verticalClinicFormGroup: FormGroup;
  verticalConfirmFormGroup: FormGroup;

  phonePrefixOptions = ['+1', '+595', '+54', '+55', '+591', '+56', '+51', '+504'];
  passwordInputType = 'password';
  visible = false;

  mode: ProgressBarMode = 'buffer';

  clinicNew: boolean;
  companys: Company[] = [];
  planes: any[];

  trackById = trackById;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,

    private cService: CommonService,
  ) { }

  ngOnInit() {

    this.cService.getLIstSimple('/register-planes').subscribe(res => {
      this.planes = res.data;
    })

    /**
    * Vertical Stepper
    * @type {FormGroup}
    */
    this.verticalAccountFormGroup = this.fb.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      name: [null],
      email: [null, Validators.required],
      phonePrefix: [this.phonePrefixOptions[1]],
      cell_phone: [null, Validators.required],
    });

    this.verticalPasswordFormGroup = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8)
      ]),
      password_confirmation: new FormControl('', [Validators.required])
    },
      RepeatValidator.mustMatch('password', 'password_confirmation')
    );




    this.verticalClinicFormGroup = this.fb.group({
      company: [null],
      company_name: [null],
      company_country: [null],
      company_address: [null],
      company_plane: [null],

    });

    this.verticalConfirmFormGroup = this.fb.group({
      terms: [null, Validators.requiredTrue]
    });



    this.verticalClinicFormGroup.get('company').valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((typedChars: string) => {

          if (typedChars !== null && typedChars.length > 1) {
            this.mode = "indeterminate";
            return this.cService.getLoadQuery('/register-clinic?query=', typedChars);
          } else {
            return of<CollectionsFormatData>();
          }
        })
      ).subscribe((result: CollectionsFormatData) => {
        this.mode = "buffer";
        if (result.data.length > 0) {
          this.companys = result.data;
        } else {
          this.cService.show('Clínica no encontrada con la combinación. Vuelva a intentarlo. ', 'info', 5000);

          this.companys = [];
        }
      });
  }


  /**
   * toggle show or hide pass
   */
  toggleVisibility() {
    if (this.visible) {
      this.passwordInputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.passwordInputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }



  /**
     * Display for autocompelte of the professional
     * @param p 
     * @returns 
     */
  displayCompany(p: Company): string {
    return p ? p.name : '';
  }



  /**
   * submit form
   * @returns 
   */
  submit() {
    let data = this.verticalAccountFormGroup.value;
    data.clinic_new = this.clinicNew;

    data.password = this.verticalPasswordFormGroup.get('password').value;
    data.password_confirmation = this.verticalPasswordFormGroup.get('password_confirmation').value;
    if (this.clinicNew) {
      data.company_name = this.verticalClinicFormGroup.get('company_name').value;
      data.company_country = this.verticalClinicFormGroup.get('company_country').value;
      data.company_address = this.verticalClinicFormGroup.get('company_address').value;
      data.company_plane = this.verticalClinicFormGroup.get('company_plane').value;
    } else {
      let c: Company = this.verticalClinicFormGroup.get('company').value;
      if (c == null) {

        this.cService.show('No se ha seleccionado una Clínica/Consultorio.', 'error');
        return;
      }
      data.company_id = c.id;
    }


    this.cService.create('/register', data)
      .subscribe({
        next: (res) => {
          switch (res.status_code) {
            case 200: {
              this.cService.show('Se ha registrado correctamente ', 'success');
              this.router.navigate(['/login']);
              //redirecion login
              break;
            }
            case 422: {
              this.cService.show('Faltan algunos datos. Revise por favor.', 'error');
            }
          }
        },
        error: (err) => { this.cService.show(err, 'error'); }
      });
    this.cService.show("Procesando los datos", 'info');

  }
  /**
   * Change new clinic or used a exist
   * @param status 
   */
  changeSelectClinicNew(status: boolean) {
    this.mode = "buffer";
    this.clinicNew = status;
    if (this.clinicNew) {
      this.companys = null;

      this.verticalClinicFormGroup.reset();
      this.verticalClinicFormGroup.controls['company'].clearValidators();
      this.verticalClinicFormGroup.controls['company_name'].setValidators([Validators.required]);
      this.verticalClinicFormGroup.controls['company_country'].setValidators([Validators.required]);
      this.verticalClinicFormGroup.controls['company_address'].setValidators([Validators.required]);
      this.verticalClinicFormGroup.controls['company_plane'].setValidators([Validators.required]);
      this.verticalClinicFormGroup.setErrors(null);
      this.verticalClinicFormGroup.updateValueAndValidity({ onlySelf: true });
    } else {

      this.verticalClinicFormGroup.reset();
      this.verticalClinicFormGroup.clearValidators();
      this.verticalClinicFormGroup.setErrors(null);
      this.verticalClinicFormGroup.controls['company'].setValidators([Validators.required]);
      this.verticalClinicFormGroup.updateValueAndValidity({ onlySelf: true });
    }
  }
}
