<div class="card overflow-auto flex flex-col md:flex-row sm:mr-6">
  <div class="bg-primary/10 p-gutter flex-auto max-w-[300px]">
    <h2 class="headline mb-4 text-primary">Mega Menú</h2>

    <p class="caption">Es un menú general, donde te enterarás de las principales actualizaciones, lanzamientos y mucho más.    </p>

    <p class="caption">En este apartado podrás ver las características principales, y página de ayuda sobre el software.</p>

    <button class="border border-primary-contrast border-solid mt-4" color="primary"  (click)="callFeedback()" mat-button type="button">DANOS TU OPINIÓN</button>
  </div>

  <!-- <div class="p-gutter flex-auto max-w-[400px]">
    <h3 class="body-2 m-0">Features</h3>

    <div class="mt-4 grid grid-cols-3">
      <a (click)="close()"
         *ngFor="let feature of features"
         [routerLink]="feature.route"
         class="text-dark p-3 text-center hover:bg-primary/10 hover:text-primary trans-ease-out rounded block no-underline">
        <mat-icon [svgIcon]="feature.icon" class="icon-xl" color="primary"></mat-icon>
        <div class="body-2 mt-2">{{ feature.label }}</div>
      </a>
    </div>
  </div> -->

  <div class="p-gutter flex-auto max-w-[500px]">
    <h3 class="body-2 m-0">PÁGINAS</h3>

    <div class="mt-6 grid grid-cols-2 gap-x-12 gap-y-4">
      <a (click)="close()"
         *ngFor="let page of pages"
         [routerLink]="page.route"
         class="text-dark body-1 no-underline hover:text-primary trans-ease-out">{{ page.label }}</a>
    </div>
  </div>
</div>

