<!-- component -->
<style>
  /*remove custom style*/

</style>

<div class="relative min-h-screen flex ">
  <div
    class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 bg-white">
    <div
      class="sm:w-1/2 xl:w-3/5 h-full hidden md:flex flex-auto items-center justify-center p-10 overflow-hidden bg-purple-900 text-white bg-no-repeat bg-cover relative"
      style="background-image: url(../assets/img/bg/bg_start.jpg);"
      >
      <div class="absolute bg-gradient-to-b from-blue-600 to-blue-500 opacity-75 inset-0 z-0"></div>
      <div class="w-full  max-w-xl z-10">
        <div class="sm:text-4xl xl:text-5xl font-bold leading-tight mb-6">Tu consultorio a dónde vayas!!!</div>
        <div class="sm:text-sm xl:text-md 2xl:text-xl text-gray-100 font-normal"> Un buen odontólogo se hace recordar por la calidad, no por el precio.</div>
      </div>
      <!---remove custom style-->
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
    <div
      class="md:flex md:items-center md:justify-center w-full sm:w-auto md:h-full  xl:w-2/5 p-8  md:p-10 lg:p-14 sm:rounded-lg md:rounded-none bg-white">
      <div class="max-w-md w-full space-y-8">
        <div class="p-6 pb-0 flex flex-col items-center justify-center">
          <div class="fill-current text-center">
            <img class="w-24" height="60" width="96" alt="logo de odonto-cloud-app" src="assets/img/primary/oc.svg">
          </div>
        </div>
        <div class="text-center mt-4">
          <h2 class="mt-6 text-3xl font-bold text-gray-900">
            Bienvenido  a Odonto Cloud
          </h2>
          <p class="body-2 text-secondary m-0">Inicie sesión en su cuenta</p>
        </div>



        <div [formGroup]="form" class="p-6 flex flex-col gap-4">
          <div class="flex flex-col">
            <mat-form-field class="flex-1">
              <mat-label>Correo</mat-label>
              <input formControlName="email" matInput required>
              <mat-error *ngIf="form.get('email').hasError('required')">Necesitamos una dirección de correo electrónico para
                iniciar sesión</mat-error>
              <mat-error *ngIf="form.get('email').hasError('email')">Tiene que ser un correo válido</mat-error>
            </mat-form-field>
            <mat-form-field class="flex-1">
              <mat-label>Contraseña</mat-label>
              <input [type]="inputType" formControlName="password" matInput required>
              <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Mostar/Ocultar" type="button">
                <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
                <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off" ></mat-icon>
              </button>
              <mat-hint>Haga clic en el ojo para alternar la visibilidad</mat-hint>
              <mat-error *ngIf="form.get('password').hasError('required')">Necesitamos una contraseña para iniciar sesión
              </mat-error>
            </mat-form-field>
          </div>
    
          <div class="flex items-center justify-between">
            <mat-checkbox class="caption" color="primary">Recuérdame</mat-checkbox>
            <a [routerLink]="['/forgot-password']" class="caption">¿Has olvidado tu contraseña?</a>
          </div>
    
          <button (click)="onSubmit(form.value)" [disabled]="!form.valid" color="primary" mat-raised-button type="button">
            INICIAR SESIÓN
          </button>
    
          <p class="text-secondary text-center">
            ¿No tienes una cuenta?<br />
            <a [routerLink]="['/register']" class="text-primary">Solicitar una Cuenta</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
