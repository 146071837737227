import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { QuicklinkModule } from 'ngx-quicklink';
import { VexRoutes } from 'src/@vex/interfaces/vex-route.interface';
import { HelpCenterComponent } from './help-center.component';

const routes: VexRoutes = [
  {
    path: '',
    component: HelpCenterComponent,
    data: {
      toolbarShadowEnabled: true
    },
    children: [
      // {
      //   path: '',
      //   redirectTo: 'getting-started'
      // },
      {
        path: 'primeros-pasos',
        loadChildren: () => import('./help-center-getting-started/help-center-getting-started.module').then(m => m.HelpCenterGettingStartedModule)
      },
      {
        path: 'precios',
        loadChildren: () => import('./help-center-pricing/help-center-pricing.module').then(m => m.HelpCenterPricingModule)
      },
      {
        path: 'faq',
        loadChildren: () => import('./help-center-faq/help-center-faq.module').then(m => m.HelpCenterFaqModule)
      },
      {
        path: 'guias',
        loadChildren: () => import('./help-center-guides/help-center-guides.module').then(m => m.HelpCenterGuidesModule)
      },
      {
        path: 'retroalimentacion',
        loadChildren: () => import('./help-feedback/help-feedback.module').then(m => m.HelpFeedbackModule)
      },
      
      

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, QuicklinkModule]
})
export class HelpCenterRoutingModule {
}
