import { Component, OnInit } from '@angular/core';
import { PopoverRef } from '../popover/popover-ref';

import {trackById, trackByLabel} from '../../utils/track-by'
import { MatDialog } from '@angular/material/dialog';
import { FeebbackFormComponent } from 'src/app/pages/feedback/feebback-form/feebback-form.component';
import { Feedback } from 'src/app/models/feedback';

export interface MegaMenuFeature {
  icon: string;
  label: string;
  route: string;
}

export interface MegaMenuPage {
  label: string;
  route: string;
}

@Component({
  selector: 'vex-mega-menu',
  templateUrl: './mega-menu.component.html'
})
export class MegaMenuComponent implements OnInit {



  features: MegaMenuFeature[] = [
    {
      icon: 'mat:layers',
      label: 'Dashboard',
      route: '/'
    },
    {
      icon: 'mat:assignment',
      label: 'AIO-Table',
      route: '/apps/aio-table'
    },
    {
      icon: 'mat:contact_support',
      label: 'Help Center',
      route: '/apps/help-center'
    },
    {
      icon: 'mat:contacts',
      label: 'Contacts',
      route: '/apps/contacts/grid'
    },
    {
      icon: 'mat:assignment',
      label: 'Scrumboard',
      route: '/apps/scrumboard/1'
    },
    {
      icon: 'mat:book',
      label: 'Documentation',
      route: '/documentation'
    }
  ];

  pages: MegaMenuPage[] = [
    {
      label: 'Licencia de uso',
      route: '/legal'
    },
    {
      label: 'Política de Privacidad',
      route: '/legal/politicas-de-privacidad'
    },
    {
      label: 'Seguridad',
      route: '/legal/seguridad'
    },
    {
      label: 'Documentación',
      route: '/ayuda/primeros-pasos'
    },
    {
      label: 'FAQ',
      route: '/ayuda/faq'
    },
    {
      label: 'Guías',
      route: '/ayuda/guias'
    },
    {
      label: 'Centro de ayuda',
      route: '/ayuda'
    },
    {
      label: 'Solicitud de Mejoras',
      route: '/ayuda/retroalimentacion'
    }
  ];
  trackById = trackById;
  constructor(private dialog: MatDialog,private popoverRef: PopoverRef<MegaMenuComponent>) { }

  ngOnInit() {
  }

  close() {
    this.popoverRef.close();
  }

  callFeedback(){


    this.dialog.open(FeebbackFormComponent, {
      width: '700px',
      maxWidth: '100%',
    }).afterClosed().subscribe((patient: Feedback) => {
      // if (patient) {
      //   //if exit set list 
      //   this.dataSource.data.push(new Feedback(patient));
      //   this.dataSource.data = this.dataSource.data.slice();
      // }
    });

  }
}
