import { BrowserModule } from '@angular/platform-browser';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import {  HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';

import { HttpErrorHandler } from './services/http-error-handler.service';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { LoginModule } from './pages/auth/login/login.module';
import { RegisterModule } from './pages/auth/register/register.module';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from 'src/app/store/app.reducers';
import { AuthEffects } from 'src/app/store/auth.effects';
import { CommonService } from './services/common.service';

import { DataService } from './services/data.service';
import {  MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import localeDeAt from '@angular/common/locales/es-PY';
import { registerLocaleData } from '@angular/common';
import {  MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/autocomplete';

import { HelpCenterModule } from './pages/help-center/help-center.module';
import { FeebbackModule } from './pages/feedback/feebback.module';
import { TooltipDirective } from './directives/tooltip.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { NgxPermissionsConfigurationStore, NgxPermissionsModule, NgxPermissionsStore, NgxRolesService, NgxRolesStore } from 'ngx-permissions';
import { AuthStateService } from './services/auth-state.service';
import { AppGuard } from './app.guard';


registerLocaleData(localeDeAt);

export const MY_FORMATS = {
  parse: {
      dateInput: 'LL',
  },
  display: {
      dateInput: 'DD / MM / YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'DD / MM / YYYY',
      monthYearA11yLabel: 'MMMM YYYY',
  },
};


@NgModule({
  declarations: [AppComponent,TooltipDirective],
  imports: [


    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,



    // Vex
    VexModule,
    CustomLayoutModule,
 
    //Pages
    LoginModule,
    RegisterModule,

    FeebbackModule,
 
     StoreModule.forRoot(reducers),
    EffectsModule.forRoot([AuthEffects]),

     //! librería de permisos -> revisar
    NgxPermissionsModule.forChild(),

    HelpCenterModule, //optimizar
  ],
  providers: [
    DataService,
    CommonService,
    HttpErrorHandler,
    NgxRolesService,
    NgxRolesStore,
    NgxPermissionsStore,// para los permisos
    NgxPermissionsConfigurationStore,
    AuthStateService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    AppGuard,
    { provide:  DEFAULT_CURRENCY_CODE, useValue: 'Gs.'},
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-PY' },
    { provide: LOCALE_ID, useValue: 'es-PY' },
    MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER
  ],
  bootstrap: [AppComponent],
  exports: [TooltipDirective],
})
export class AppModule { }
