import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { VexConfigName } from '../@vex/config/config-name.model';
import { ColorSchemeName } from '../@vex/config/colorSchemeName';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ColorVariable, colorVariables } from '../@vex/components/config-panel/color-variables';

// import icLayers from '@iconify/icons-ic/twotone-layers';
// import icDateRange from '@iconify/icons-ic/twotone-date-range';
// import icSettgins from '@iconify/icons-ic/twotone-settings';
// import icPeople from '@iconify/icons-ic/people';
// import icStyle from '@iconify/icons-ic/twotone-style';
// import icLocalGroceryStore from '@iconify/icons-ic/twotone-local-grocery-store';
// import icShoppingBasket from '@iconify/icons-ic/twotone-shopping-basket';
// import icHispital from '@iconify/icons-ic/twotone-local-hospital';
// import icSeatFlat from '@iconify/icons-ic/twotone-airline-seat-flat-angled';



import { Observable } from 'rxjs';
import { State } from './store/auth.reducers';
import { Store } from '@ngrx/store';
import { AuthState } from './store/app.reducers';
import { Role } from './models/work-group.model';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'vex';
  isAuthnoticated: Observable<State>;



  constructor(
    private store: Store<AuthState>,
    private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    showConfigButton: false,
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    this.configService.updateConfig({
      sidenav: {
        title: 'Odonto-Cloud',
        imageUrl: 'assets/img/primary/oc.svg',
        showCollapsePin: true
      },
      // showConfigButton: false,
      footer: {
        visible: false
      }
    });


     /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
     this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as VexConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color
              }
            }
          });
        }
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
        });
      }
    });



    this.navigationService.items = [
      {
        type: 'link',
        label: 'Tablero',
        route: '/principal',
        icon: 'mat:layers'
      },
      {
        type: 'link',
        label: 'Agenda',
        route: '/agenda',
        icon: 'mat:date_range',
        // badge: {
        //   value: '12',
        //   bgClass: 'bg-deep-purple',
        //   textClass: 'text-deep-purple-contrast',
        // },

      },
      {
        type: 'link',
        label: 'Pacientes',
        route: '/pacientes',
        icon: 'mat:people',
        // badge: {
        //   value: '12',
        //   bgClass: 'bg-deep-purple',
        //   textClass: 'text-deep-purple-contrast',
        // },

      },

      {
        type: 'link',
        label: 'Notificaciones Whatsapp',
        route: '/notificaciones-whatsapp',
        icon: 'mat:chat_bubble',
        // badge: {
        //   value: '12',
        //   bgClass: 'bg-deep-purple',
        //   textClass: 'text-deep-purple-contrast',
        // },

      },

      {
        type: 'link',
        label: 'Resumen Financiero',
        route: '/resumen-financiero',
        icon: 'mat:account_balance',
        requiredRole:[Role.Admin,Role.Manager]
        // badge: {
        //   value: '12',
        //   bgClass: 'bg-deep-purple',
        //   textClass: 'text-deep-purple-contrast',
        // },

      },
      {
        type: 'link',
        label: 'Resumen Clínico',
        route: '/resumen-clinica',
        icon: 'mat:foundation',
        requiredRole:[Role.Admin,Role.Manager]
        // badge: {
        //   value: '12',
        //   bgClass: 'bg-deep-purple',
        //   textClass: 'text-deep-purple-contrast',
        // },

      },
      {
        type: 'link',
        label: 'Productos en línea',
        route: '/pedidos',
        icon: 'mat:local_grocery_store'
      },
      {
        type: 'subheading',
        label: 'Configuraciones',
        children: [


          {
            type: 'link',
            label: 'Suministros',
            route: '/productos',
            icon: 'mat:shopping_basket'
          },

          

          {
            type: 'link',
            label: 'Tratamientos',
            route: '/tratamientos',
            icon: 'mat:airline_seat_flat_angled'
          },
          {
            type: 'link',
            label: 'Enfermedades',
            route: '/enfermedades',
            icon: 'mat:local_hospital'
          },
          {
            type: 'link',
            label: 'Ajustes Generales',
            route: '/ajustes',
            icon: 'mat:settings',
            requiredRole:[Role.Admin,Role.Manager]
          },

        ]
      }
    ];
  }


  ngOnInit(): void {
    this.isAuthnoticated = this.store.select('authState');
  }
}
