import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[vexTooltip]',

})
export class TooltipDirective {

  
  @Input('vexTooltip') tooltipText: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.setAttribute(this.el.nativeElement, 'data-vexTooltip', this.tooltipText);
  }

}
