import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';


import { CommonService } from 'src/app/services/common.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';



@Component({
  selector: 'vex-profile-pass',
  templateUrl: './profile-pass.component.html',
  styleUrls: ['./profile-pass.component.scss']
})
export class ProfilePassComponent implements OnInit {


  private baseUri: string = '/profile-pass';
  form: FormGroup;

  inputTypeP = 'password';
  inputTypeR = 'password';
  visibleP = false;
  visibleR = false;





  constructor(
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<ProfilePassComponent>,
    private fb: FormBuilder,
    private cService: CommonService,
    private ts: TokenStorageService) {
  }

  ngOnInit() {

    this.form = this.fb.group({
      password: [null || '', [Validators.required]],
      password_confirmation: [null || '', [Validators.required]],
    });





  }



  toggleVisibilityP() {
    if (this.visibleP) {
      this.inputTypeP = 'password';
      this.visibleP = false;
      this.cd.markForCheck();
    } else {
      this.inputTypeP = 'text';
      this.visibleP = true;
      this.cd.markForCheck();
    }
  }

  toggleVisibilityR() {
    if (this.visibleR) {
      this.inputTypeR = 'password';
      this.visibleR = false;
      this.cd.markForCheck();
    } else {
      this.inputTypeR = 'text';
      this.visibleR = true;
      this.cd.markForCheck();
    }
  }



  save(valueForm) {
    this.ts.isGroupWorkNow().then((dataGroup) => {


      let id = dataGroup.is_user;
      valueForm.is_group = dataGroup.is_group;
      valueForm.is_updated = dataGroup.is_user;
      valueForm.is_active = 1;
      this.cService.update(this.baseUri, id, valueForm)
        .subscribe({
          next: (res) => {
            switch (res.status_code) {
              case 200: {
                this.cService.show('Se actualizado correctamente ', 'success');
                this.dialogRef.close(true);
                break;
              }
              case 422: {
                for (let a of res.errors) {
                  this.cService.show(a, 'warning');
                }
                break;
              }
            }
          },
          error: (err) => { this.cService.show(err, 'error'); }
        });
    });

  }

}
