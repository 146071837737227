import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable, from, EMPTY } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { TokenStorageService } from "../services/token-storage.service";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private ts: TokenStorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('Interceptor triggered:', req); //!revisar
    return from(this.ts.getAccessToken()).pipe(
      switchMap(authToken => {
        // console.log("Intercept");
        // console.log(authToken);

        if (authToken) {
          // Agregar el token de acceso a la cabecera de la solicitud
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${authToken}`,
            },
          });

          if (req.url == "https://api.ipify.org/?format=json") {
            const headers = new HttpHeaders({});
            const requestChange = req.clone({ headers });
            return next.handle(requestChange);
          }
        }

        return next.handle(req);
      }),
      catchError(() => EMPTY)
    );
  }
}
