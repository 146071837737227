let production:boolean=false;


export const AppConfig = {

    
    appName: 'OdontoCloud APP',
    // apiEndpoint: 'http://manager.odonto.test/api',
    // appHost: 'http://manager.odonto.test',  
    // appHostMedia: 'http://manager.odonto.test',  // for photo images serve S3

    apiEndpoint: 'https://manager.odonto-cloud.com/api',
    appHost: 'https://manager.odonto-cloud.com',  
    appHostMedia: 'https://manager.odonto-cloud.com',  // 
    grant_type: 'password',
    client_id: 'webapp',

    tokenAutentication: 'Bearer yaKwMvTdPRvhS4RloNieE4V4EbEPcXM8zRfvVsaA', //ya no se utiliza


    key_sign: "sefdwfafg23423fgasfg32423"

  //ng build --build-optimizer --configuration=production
};