
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

export class Entity {
  access_token: string;
  user: string;
  workGroups: string[];
  email: string;
  password: string;
  status_code: number;
  message: string;
  errors: any[];
  ip: string;
}

import { AppConfig } from '../config/app';
import { catchError, map } from 'rxjs/operators';
import { TokenStorageService } from './token-storage.service';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
// import { DeviceDetectorService } from 'ngx-device-detector';
@Injectable({
  providedIn: 'root'
})
export class AuthService {


  LOGIN_URL: string = AppConfig.apiEndpoint + '/login';
  LOGOUT_URL: string = AppConfig.apiEndpoint + '/logout';
  REFRESH_URL: string = AppConfig.apiEndpoint + '/refresh';
  SIGNUP_URL: string = AppConfig.apiEndpoint + '/authenticate';

  private userRolesSubject = new BehaviorSubject<string>("");
  userRoles$: Observable<string> = this.userRolesSubject.asObservable();



  public handleError: HandleError;
  private ipAddress = "";

  // Create a stream of logged in status to communicate throughout app
  loggedIn: boolean = false;
  loggedInSubject = new BehaviorSubject<boolean>(this.loggedIn);
  loggedInObjeserva = new Observable<boolean>();


  // private userSubject: BehaviorSubject<User>;
  // public user: Observable<User>;


  constructor(
    private router: Router, private http: HttpClient, public ts: TokenStorageService, httpErrorHandler: HttpErrorHandler,
    private permissionsService: NgxPermissionsService
    //,private deviceService: DeviceDetectorService
  ) {

    this.handleError = httpErrorHandler.createHandleError('Authservice');
    this.loggedInSubject = new BehaviorSubject<boolean>(this.loggedIn);
    this.loggedInObjeserva = this.loggedInSubject.asObservable();
  }

  getUser(form): Observable<any> {
    // console.log(form.value);
    return this.http.post(AppConfig.apiEndpoint + '/register', form.value);
  }


  // Método para establecer los roles del usuario
  setUserRoles(roles: string) {
    this.userRolesSubject.next(roles);
  }




  /**
  * [login solicitud de login]
  * @param  {[type]} credentials [description]
  * @return {[type]}             [description]
  */
  authenticate(credentials: Entity): Observable<Entity> {


    const sendData = {
      email: credentials.email,
      password: credentials.password,
      // device: this.deviceService.userAgent + " ip: "+this.ipAddress,
      device: 'local' + this.ipAddress,
    };
    return this.http.post<Entity>(this.LOGIN_URL, sendData)
      .pipe(
        map(res => {


          switch (res.status_code) {
            case 200: {

              this.ts.setAccessToken(res.access_token);
              this.ts.setProfile(JSON.stringify(res.user));
              this.ts.setWorkGroup(JSON.stringify(res.workGroups));

              console.log(this.ts.getAccessToken());
              // validar si existe ya en un localStorage actual revisar lo pro y contras
              if (res.workGroups.length > 0) {
                this.ts.setWorkGroupNow(JSON.stringify(res.workGroups[0]));
                // Establece los roles del usuario en el servicio de autenticación
                this.ts.isRoles().then((data) => {
                  // console.log(data);
                  this.setUserRoles(data);
                });
                //se establece los permisos
                this.ts.isPermissions().then((data) => {
                  // console.log(data);
                  this.permissionsService.loadPermissions(data);
                });
              } else {
                this.ts.setWorkGroupNow(null);
                this.setUserRoles('null');
              }
              this.setLoggedIn(true);
              break;
            }
            case 422: {
              // this.snackbar.open("Las credenciales no son correctas", 'Error', {
              //   duration: 10000
              // });
            }
          }

          
          
          return res;
        }),
        catchError(this.handleError('authenticate', credentials))
      );

  }
  /**
   * 
   * @returns retorna is esta logueado
   */
  public isLogged() {
    return this.loggedInSubject.value;
  }

  /**
   * [setLoggedIn estado de login]
   * @param  {boolean} value [description]
   * @return {[type]}        [description]
   */
  public setLoggedIn(value: boolean): void {
    // Update login status subject
    this.loggedInSubject.next(value);
    this.loggedIn = value;
  }

  /**
  * Cerrar sesión
  * Remove tokens and profile and update login status subject
  */
  public logout(): void {
    if (this.ts.getAccessToken()) {

      this.ts.getProfile().then((res) => {
        // console.log(data);
        const p = JSON.parse(res);
        const data = {
          user_id: p.id
        }
        this.http.post<Entity>(this.LOGOUT_URL, data)
          .pipe(
            map(res => {
              this.ts.clear();
              //  this.userProfile = undefined;
              this.setLoggedIn(false);
              return res;
            }),
            catchError(this.handleError('logout'))
          )
          .subscribe({
            next: (res) => {
              this.router.navigate(['/login']);
            },
            error: (err) => {
              this.ts.clear();
              //this.userProfile = undefined;
              this.setLoggedIn(false);
              this.router.navigate(['/login']);
            }
          });  
      });
       

    } else {

      this.ts.clear();
      //  this.userProfile = undefined;
      this.setLoggedIn(false);
      this.router.navigate(['/login']);
    }
  }

  /**
   * get ip client
   */
  public getIPAddress() {

    let httpOptions = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': "*"
    };
    this.http.get("https://api.ipify.org/?format=json", { headers: httpOptions }).subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }





}
