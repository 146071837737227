import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// http

// config
import { AppConfig } from '../config/app';
import { Entity } from 'src/app/models/entity';
import { CollectionsFormatData } from 'src/app/models/collections-format-data';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpErrorHandler, HandleError } from '../services/http-error-handler.service';
import { NotifierService } from './notifier.service';


@Injectable()
export class CommonService {
  baseUrl: string;
  // httpOptions: {}; 

  // public loading: Loading; //se crea objeto para notificar que se esta procesando algo
  // private options: RequestOptions;
  private handleError: HandleError;

  constructor(private httpClient: HttpClient,
    private httpErrorHandler: HttpErrorHandler,
    private notifierService: NotifierService) {
    // this.httpOptions = {
    //   headers: new HttpHeaders({ 'Authorization': AppConfig.tokenAutentication })
    // };

    this.baseUrl = AppConfig.apiEndpoint;

    this.handleError = httpErrorHandler.createHandleError('CommonService');
  }


  public getDataJsonDb(url: string): Observable<Entity> {
    return this.httpClient.get<Entity>(url);
  }

  /**
   * [getList description]
   * @param  {string}                            sort     [description]
   * @param  {string}                            order    [description]
   * @param  {number}                            page     [description]
   * @param  {number}                            pageSize [description]
   * @return {Observable<CollectionsFormatData>}          [description]
   */
  public getList(uriBase: string, sort: string, order: string, page: number, pageSize: number): Observable<CollectionsFormatData> {
    const uri = `${uriBase}&page=${page + 1}&sort=${sort}&order=${order}&per_page=${pageSize}`;
    return this.httpClient.get<CollectionsFormatData>(`${this.baseUrl}${uri}`);
  }


  public getLIstSimple(uriBase: string): Observable<CollectionsFormatData> {

    return this.httpClient.get<CollectionsFormatData>(`${this.baseUrl}${uriBase}`);
  }

  /**
   * [loadAll obtiene la coleccion de datos del uri solicitado]
   * @param  {string} uri [Especifiación de ruta el recurso solicitado ejemplo /]
   * @return {[type]}     [description]
   */
  public getLoadAll(uri: string, paramsSend: HttpParams): Observable<CollectionsFormatData> {
    return this.httpClient.get<CollectionsFormatData>(`${this.baseUrl}${uri}`, { params: paramsSend });
  }




  /**
   * [loadAll obtiene la coleccion de datos del uri solicitado]
   * @param  {string} uri [Especifiación de ruta el recurso solicitado ejemplo /]
   * @return {[type]}     [description]
   */
  public getDataQuery(uri: string, paramsSend: HttpParams): Observable<Entity> {
    return this.httpClient.get<Entity>(`${this.baseUrl}${uri}`, { params: paramsSend });
  }




  /**
   * [getLoadQuery description]
   * @param  {string} uri   [description]
   * @param  {string} query [description]
   * @return {[type]}       [description]
   */
  public getLoadQuery(uri: string, query: string): Observable<any> {
    return this.httpClient.get<CollectionsFormatData>(`${this.baseUrl}${uri}${query}`)
      .pipe(catchError(this.handleError()));
  }


  /**
   * [getLoadQueryByIdAndIsGroup is group and id of the object]
   * @param uri 
   * @param id 
   * @param is_group 
   * @returns 
   */
  public getLoadQueryByIdAndIsGroup(uri: string, id: number,  paramsSend: HttpParams): Observable<Entity> {
    return this.httpClient.get<Entity>(`${this.baseUrl}${uri}/${id}`, { params: paramsSend });
  }

  /**
   * [getLoadQueryById description]
   * @param  {string} uri [description]
   * @param  {string} id  [description]
   * @return {[type]}     [description]
   */
  public getLoadQueryById(uri: string, id: number): Observable<Entity> {
    return this.httpClient.get<Entity>(`${this.baseUrl}${uri}/${id}`);
  }


  /**
   * [create send data to server (post)]
   * @param  {string} uri      [description]
   * @param  {Object} dataSend [description]
   * @return {[type]}          [description]
   */
  public create(uri: string, dataSend: Object): Observable<any> {
    return this.httpClient.post<Entity>(`${this.baseUrl}${uri}`, dataSend).pipe(catchError(this.handleError()));

  }
  /**
   * [update send data to serve (put)]
   * @param  {string}    uri      [description]
   * @param  {number |        string}      id [description]
   * @param  {Object}    dataSend [description]
   * @return {[type]}             [description]
   */
  public update(uri: string, id: number | string, dataSend: Object): Observable<any> {
    return this.httpClient.put<Entity>(`${this.baseUrl}${uri}/${id}`, dataSend).pipe(catchError(this.handleError()));

  }
  /**
   * [remove send data to serve (delete)]
   * @param  {string}    uri [description]
   * @param  {number |   string}      id [description]
   * @return {[type]}        [description]
   */
  public remove(uri: string, id: number | string): Observable<any> {
    return this.httpClient.delete(`${this.baseUrl}${uri}/${id}`).pipe(catchError(this.handleError()));

  }

  /**
   * Obtiene el reporte
   * 
   * @param {string} uri 
   * @returns 
   * @memberof CommonService
   */
  public getReport(uri: string): Observable<Blob> {
    return this.httpClient.get(`${this.baseUrl}${uri}`, {
      responseType: 'blob'
    });
  }



  /**
   * conviente el blob para visualizar el pdf
   * 
   * @param {any} blob 
   * @memberof CommonService
   */
  public downloadFileToPdf(blob: Blob, fileName: string): number {

    // if (blob.type !== 'application/x-download') {
    //   return 0;
    // }


    const newBlob = new Blob([blob], { type: 'application/pdf' });
    const d = new Date();

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //   window.navigator.msSaveOrOpenBlob(newBlob);
    //   return;
    // }

    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);



    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', fileName + d.toISOString().slice(0, 19).replace(/:/g, '-').replace(/T/g, '-'));
    const event = document.createEvent('MouseEvents');
    event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
    link.dispatchEvent(event);



    setTimeout(function (): void {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
    return 1;
  }
  /**
    * 
    * 
    * @param {string} message mensaje que se quiere visualizar
    * @param {string} typeMess tipo: ['error','success','info','warning']
    * @param {number} [duration] duracción en milisegundos 
    * @memberof MessageToast
    */
  public show(message: string, typeMess: 'success' | 'error' | 'info' | 'warning', duration?: number) {
    this.notifierService.show(message, typeMess, duration);
  }








}
