import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarNotificationsDropdownComponent } from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { CommonService } from 'src/app/services/common.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'vex-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent implements OnInit {

  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;

  dropdownOpen: boolean;

  messObs: Observable<any>;
  showNotification = false;

  constructor(private popover: PopoverService,
    private cd: ChangeDetectorRef, private cService: CommonService,
    private ts: TokenStorageService) { }

  ngOnInit() {
    this.ts.isDataProfile().then((data) => {
      this.messObs = this.cService.getLIstSimple('/notification-unread/' + data.id);

      this.messObs.
        subscribe({
          next: (res) => {
            if (res.data.length > 0) {
              this.showNotification = true;

              this.cService.show('Tienes mensajes sin leer.', 'info', 10000);
            }
          },
          error: (err) => {
            this.cService.show(err, 'error');
          }
        });
    });






  }


  showStatusChangedHandler(status) {
    //traaja mas
    // this.showNotification = status;
    // console.log(status);
  }

  showPopover() {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarNotificationsDropdownComponent,
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe((a: any) => {
      this.dropdownOpen = false;
      this.cd.markForCheck();


      // console.log(a);
      if (a.data != null) {
        if (a.data.notifications?.length <= 0) {
          this.messObs = null;
        }
      }

    });



  }
}
