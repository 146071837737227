<vex-scrollbar class="h-full relative">
  <div @stagger>
    <div class="min-h-screen p-6 bg-gray-100 flex items-center justify-center">
      <div class="container max-w-screen-lg mx-auto">
        <div class="lg:flex lg:items-center lg:justify-between xs:mb-2 sm:mb-2">


          <div class="flex-1 min-w-0">
            <h2 class="font-semibold text-xl text-gray-600">Solicitud de Cuenta Odonto-Cloud-App</h2>
            <p class="text-gray-500 mb-6">Las cuentas una vez aprobadas se podrán utilizar. Puede solicitar a soporte
              para
              crear la cuenta.</p>



          </div>
          <div class="mt-5 flex lg:mt-0 lg:ml-4 ">

            <p class="text-secondary text-center ">
              ¿Ya tienes una cuenta?<br />
              <a [routerLink]="['/login']" class="text-primary">Ingresar</a>
            </p>
          </div>
        </div>
        <div class=" ">
          <div class="grid gap-4 ">
            <div @fadeInUp class="card overflow-hidden">
              <mat-vertical-stepper #verticalStepper="matVerticalStepper" [linear]="true">
                <ng-template matStepperIcon="edit">
                  <mat-icon svgIcon="mat:done_all"></mat-icon>
                </ng-template>

                <ng-template matStepperIcon="done">
                  <mat-icon svgIcon="mat:done_all"></mat-icon>
                </ng-template>

                <mat-step [stepControl]="verticalAccountFormGroup">
                  <form [formGroup]="verticalAccountFormGroup">
                    <ng-template matStepLabel>Datos de la Cuenta</ng-template>

                    <div class="title mt-4">Crear una cuenta</div>
                    <div class="subheading-1 text-gray-500 text-sm ">Los datos que nos proporciona deben ser suyos.
                    </div>

                    <div class="mt-4 flex flex-col">

                      <div class="p-6 flex flex-row gap-4 flex-auto ">
                        <mat-form-field class="flex-1">
                          <mat-label>Nombre</mat-label>
                          <input formControlName="first_name" matInput required>
                          <mat-hint>Se necesita el nombre real.</mat-hint>
                          <mat-error *ngIf="verticalAccountFormGroup.get('first_name').hasError('required')">
                            Necesitamos un nombre para identificarlo.</mat-error>
                        </mat-form-field>

                        <mat-form-field class="flex-1">
                          <mat-label>Apellido</mat-label>
                          <input formControlName="last_name" matInput required>
                          <mat-hint>Es necesario especificar el apellido.</mat-hint>
                          <mat-error *ngIf="verticalAccountFormGroup.get('last_name').hasError('required')">
                            Necesitamos un apellido para identificarlo.</mat-error>

                        </mat-form-field>

                        <mat-form-field class="flex-1">
                          <mat-label>Nick</mat-label>
                          <input formControlName="name" matInput>
                          <mat-hint>Un nick para identificarse. Si no lo especifica, la app le asignará uno.
                          </mat-hint>
                        </mat-form-field>
                      </div>
                      <div class="p-6 flex flex-row gap-4 flex-auto ">
                        <mat-form-field class="flex-1">
                          <mat-label>Correo Electrónico</mat-label>
                          <input formControlName="email" type="email" matInput required [email]="true">
                          <mat-hint align="end">Asegúrese que sea válido. Ej.:example@example.com</mat-hint>
                          <mat-error *ngIf="verticalAccountFormGroup.get('email').hasError('required')">Necesitamos un
                            correo para crear la cuenta.</mat-error>
                          <mat-error *ngIf="verticalAccountFormGroup.get('email').hasError('email')">Correo no válido.
                          </mat-error>
                        </mat-form-field>

                        <!-- <div class="flex-1" > -->
                        <!-- <mat-form-field class="vex-flex-form-field" fxFlex="none">
                            <mat-label>Prefijo</mat-label>
                            <mat-select formControlName="phonePrefix">
                              <mat-option ="let option of phonePrefixOptions; trackBy: trackById" [value]="option">{{ option }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field> -->

                        <mat-form-field class="flex-1">
                          <mat-label>Nro. Teléfono Móvil</mat-label>
                          <input formControlName="cell_phone" matInput required>
                          <mat-hint align="end">Asegúrese que el nro. teléfono móvil sea válido. El formato es el
                            internacional (prefijo país) (prefijo operadora) (nro teléfono) Ej. +595 971 996710
                          </mat-hint>
                          <mat-error *ngIf="verticalAccountFormGroup.get('cell_phone').hasError('required')">
                            Necesitamos un nro de teléfono móvil para contactarlo..</mat-error>
                        </mat-form-field>

                      </div>
                    </div>

                    <div class="flex items-right justify-center ">
                      <button (click)="verticalStepper.reset()" [disabled]="verticalAccountFormGroup.pristine"
                        color="primary" mat-button type="button">RESETEAR
                      </button>
                      <button [disabled]="verticalAccountFormGroup.invalid" color="primary" mat-raised-button
                        matStepperNext>
                        SIGUIENTE
                      </button>
                    </div>
                  </form>
                </mat-step>

                <mat-step [stepControl]="verticalPasswordFormGroup">
                  <form [formGroup]="verticalPasswordFormGroup">
                    <ng-template matStepLabel>Seleccione una contraseña</ng-template>

                    <div class="title mt-4">Seleccione una contraseña</div>
                    <div class="subheading-1 text-gray-500 text-sm ">Ingrese su contraseña que utilizará para iniciar
                      sesión.</div>

                    <div class="mt-4 flex flex-col">

                      <mat-form-field class="flex-1">
                        <mat-label>Contraseña</mat-label>
                        <input [type]="passwordInputType" formControlName="password" matInput required>
                        <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Mostrar/Ocultar"
                          type="button">
                          <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
                          <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
                        </button>


                        <mat-hint>Su contraseña debe tener al menos 8 caracteres</mat-hint>
                        <mat-error *ngIf="verticalPasswordFormGroup.get('password').hasError('minlength')">La
                          contraseña debe tener al menos 8 caracteres.</mat-error>
                        <mat-error *ngIf="verticalPasswordFormGroup.get('password').hasError('required')">La
                          contraseña es requerida.</mat-error>

                      </mat-form-field>

                      <mat-form-field class="flex-1">
                        <mat-label>Confirmar contraseña</mat-label>
                        <input [type]="passwordInputType" formControlName="password_confirmation" matInput required>
                        <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Mostrar/Ocultar"
                          type="button">
                          <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
                          <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
                        </button>
                        <mat-hint>Vuelva a escribir su contraseña</mat-hint>
                        <mat-error *ngIf="verticalPasswordFormGroup.get('password_confirmation').hasError('mustMatch')">
                          Las
                          contraseñas no son iguales.</mat-error>
                        <mat-error *ngIf="verticalPasswordFormGroup.get('password_confirmation').hasError('required')">
                          La
                          contraseña debe confirmarse.</mat-error>

                      </mat-form-field>
                    </div>

                    <div class="actions flex items-center gap-4" >
                      <button color="primary" mat-button matStepperPrevious type="button">ANTERIOR</button>
                      <button [disabled]="verticalPasswordFormGroup.invalid" color="primary" mat-raised-button
                        matStepperNext>
                        SIGUIENTE
                      </button>
                    </div>
                  </form>
                </mat-step>

                <mat-step [stepControl]="verticalClinicFormGroup">


                  <form [formGroup]="verticalClinicFormGroup">
                    <ng-template matStepLabel>Clínica</ng-template>

                    <div class="title mt-4">Seleccione </div>
                    <div class="subheading-1 text-gray-500 text-sm ">Si quiere unirse a una clínica o quiere tener su
                      propia clínica.</div>
                    <div class="grid gap-4 grid-cols-4 py-4">

                      <button color="primary" (click)="changeSelectClinicNew(false)" mat-stroked-button>Unirme a una
                        creada</button>
                      <button color="primary" (click)="changeSelectClinicNew(true)" mat-flat-button>Crear
                        Nuevo</button>
                    </div>

                    <div class="flex flex-col gap-4" *ngIf="clinicNew==false" >
                      <div class="  m-0">
                        <mat-progress-bar [mode]="mode" value="0"></mat-progress-bar>

                      </div>
                      <mat-form-field class=" flex-auto">

                        <mat-label>Clínica/Consultorio</mat-label>
                        <input matInput placeholder="Escriba el nombre de la clínica" aria-label="Clínica"
                          [matAutocomplete]="autoCompany" autofocus formControlName="company">

                        <!-- <mat-icon [icIcon]="icPermIdentity"  matPrefix></mat-icon> -->
                        <mat-autocomplete #autoCompany="matAutocomplete" [displayWith]="displayCompany">
                          <mat-option *ngFor="let p of companys; trackBy: trackById" [value]="p" class="custom-option">

                            <div class=" w-full  flex items-center  shadow ">
                              <div class="relative flex items-center space-x-4">
                                <img [src]="p.profile_photo_url" alt="My profile" class="w-8 h-8 rounded-full">
                                <span
                                  class="absolute h-2 w-2 bg-green-400 rounded-full bottom-0 right-0 border-2 border-white"></span>
                              </div>
                              <div class="flex-grow p-3">
                                <div class="font-semibold text-gray-700">
                                  {{ p?.name }} {{ p?.ruc }}
                                </div>
                                <div class="text-sm text-gray-500">
                                  Tel.: {{ p?.movil }}: {{ p?.address }} País: {{ p?.country }}
                                </div>
                              </div>
                              <div class="p-2">
                                <span class="block h-4 w-4 bg-blue-400 rounded-full bottom-0 right-0"></span>
                              </div>
                            </div>
                          </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="verticalClinicFormGroup.get('company').hasError('required')">Seleccione una
                          Clínica.</mat-error>
                      </mat-form-field>


                    </div>

                    <div class=" flex flex-col gap-4" *ngIf="clinicNew==true" >
                      <div class="flex flex-col sm:flex-row gap-4" >
                        <mat-form-field class="flex-auto">
                          <mat-label>Nombre Clínica</mat-label>
                          <input formControlName="company_name" matInput required>
                          <mat-hint>Se necesita el nombre real.</mat-hint>
                        </mat-form-field>

                        <mat-form-field class="flex-auto">
                          <mat-label>Plan del servicio</mat-label>
                          <mat-select formControlName="company_plane">
                            <mat-option *ngFor="let p of planes; trackBy: trackById" [value]="p.id">
                              {{p.name}}
                            </mat-option>
                          </mat-select>

                          <mat-hint>Puede elegir ya el plan que mas prefieras.</mat-hint>
                        </mat-form-field>

                       
                      </div>
                      <div class="flex flex-col sm:flex-row gap-4"   >
                        <mat-form-field class="flex-auto">
                          <mat-label>Dirección</mat-label>
                          <input formControlName="company_address" matInput required>
                          <mat-hint align="end">Dirección actual de la Clínica/Consultorio</mat-hint>
                        </mat-form-field>
                        <mat-form-field class="flex-auto">
                          <mat-label>País</mat-label>
                          <input formControlName="company_country" matInput required>
                          <!-- <mat-hint>Un nick para identificarse. Si no lo especifica, la app le asignará uno.</mat-hint> -->
                        </mat-form-field>

                      </div>
                    </div>

                    <div class="actions flex items-center gap-4">
                      <button color="primary" mat-button matStepperPrevious type="button">ANTERIOR</button>
                      <button [disabled]="verticalClinicFormGroup.invalid" color="primary" mat-raised-button
                        matStepperNext>
                        SIGUIENTE
                      </button>
                    </div>
                  </form>
                </mat-step>

                <mat-step [stepControl]="verticalConfirmFormGroup">
                  <form [formGroup]="verticalConfirmFormGroup">
                    <ng-template matStepLabel>Confirmar Términos y Condiciones</ng-template>

                    <div class="title mt-4">Ya casi estás ahí!</div>
                    <div class="subheading-1 text-gray-500 text-sm  ">Para finalizar el registro, acepte nuestros
                      términos de servicio y haga
                      clic en "Solicitar una Cuenta"
                    </div>

                    <div class="mt-4  " >
                      <mat-checkbox class="checkbox" formControlName="terms">
                        He leído y acepto los términos de servicio y las Políticas de Privacidad *
                      </mat-checkbox>
                    </div>

                    <div class="actions mt-4 flex items-center gap-4" >
                      <button color="primary" mat-button matStepperPrevious type="button">ANTERIOR</button>
                      <button (click)="submit()" [disabled]="verticalConfirmFormGroup.invalid" color="primary"
                        mat-raised-button matStepperNext>
                        SOLICITAR UNA CUENTA
                      </button>
                    </div>
                  </form>

                </mat-step>
              </mat-vertical-stepper>


            </div>
          </div>

        </div>




        <a href="https://wa.me/595971996710?&text=Quisiera%20una%20cuenta%20de%200donto-Cloud" target="_blank"
          class="md:absolute bottom-0 right-0 p-4 float-right">
          <img src="https://www.svgrepo.com/show/176768/whatsapp-social-media.svg" alt="Soporte"
            class="transition-all rounded-full w-14 -rotate-45 hover:shadow-sm shadow-lg ">
        </a>
      </div>
    </div>

  </div>


  <!-- <script>
    var url = 'https://wati-integration-service.clare.ai/ShopifyWidget/shopifyWidget.js?86019';
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = url;
    var options = {
      "enabled": true,
      "chatButtonSetting": {
        "backgroundColor": "#4dc247",
        "ctaText": "",
        "borderRadius": "656",
        "marginLeft": "60",
        "marginBottom": "80",
        "marginRight": "50",
        "position": "right"
      },
      "brandSetting": {
        "brandName": "Odonto-Cloud",
        "brandSubTitle": "Tu consultorio odontológico a donde vayas.",
        "brandImg": "https://cdn.clare.ai/wati/images/WATI_logo_square_2.png",
        "welcomeText": "Necesito crear un cuenta,",
        "messageText": "",
        "backgroundColor": "#0a5f54",
        "ctaText": "Solicitar Soporte",
        "borderRadius": "4",
        "autoShow": false,
        "phoneNumber": "595971996710"
      }
    };
    s.onload = function () {
      CreateWhatsappChatWidget(options);
    };
    var x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  </script> -->
</vex-scrollbar>