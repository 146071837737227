import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { MessageService } from './message.service';
import { errorMessagesResponse } from '../utils/error-message-response';
import { Router } from '@angular/router';

/** Type of the handleError function returned by HttpErrorHandler.createHandleError */
export type HandleError = <T> (operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;

@Injectable()
export class HttpErrorHandler {
  constructor(private messageService: MessageService, private router: Router) { }

  /** Create curried handleError function that already knows the service name */
  createHandleError = (serviceName = '') => <T> (operation = 'operation', result = {} as T) => this.handleError(serviceName, operation, result);

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   * @param serviceName = name of the data service that attempted the operation
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError<T>(serviceName = '', operation = 'operation', result = {} as T) {





    return (haldleErrorObj: HttpErrorResponse): Observable<T> => {
      let mess:any = 'Algo malo sucedio; Por favor, inténtelo de nuevo más tarde';
      // TODO: send the error to remote logging infrastructure
      console.error(haldleErrorObj); // log to console instead

      // const message = (haldleErrorObj.error instanceof ErrorEvent) ?
      //   haldleErrorObj.error.message :
      //   `server returned code ${haldleErrorObj.status} with body '${haldleErrorObj.error}'`;

      // // TODO: better job of transforming error for user consumption
      // this.messageService.add(`${serviceName}: ${operation} failed: ${message}`);


      if (haldleErrorObj.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
       // console.error('An error occurred:', haldleErrorObj.error.message);
        mess = haldleErrorObj.error.message;
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        // console.error('body was:', haldleErrorObj);
        switch (haldleErrorObj.status) {
          case 200:
            if (haldleErrorObj.error.message === 'Successfully logged out') {
              mess = 'Se ha cerrado seción correctamente.';
            }

            break;
          case 304:
            if (haldleErrorObj.error.message === 'Successfully logged out') {
              mess = 'Se ha cerrado seción correctamente.';
            }
           
            break;
          case 400:
            mess = 'Expiración de sesión';
           
            break;           
          case 401:
            // console.log("401")
            if (haldleErrorObj.error.error === 'invalid_credentials') {
              mess = 'Credenciales inválidas.';
            }
            if (haldleErrorObj.error.message === "Unauthenticated.") {
              mess = 'Token Inválido, Iniciar Sesión nuevamente.';
              this.router.navigate(['/login']);
            }
          //  console.log('err ' + haldleErrorObj.error);
            break;
          case 402:
          //  console.log('err ' + haldleErrorObj.error);
            break;

          case 422:
            mess = errorMessagesResponse(haldleErrorObj);

            
            // if (haldleErrorObj.error.message === '422 Unprocessable Entity') {
            //   mess = 'Algunos datos introducidos no son correctos.';
            // }else{
            //   mess = haldleErrorObj.error.message;
            // }
            //console.log('err '+ mess);
            break;

          case 500:
            // console.log('err ' + haldleErrorObj.error);
            mess= haldleErrorObj.error
            // if (haldleErrorObj.error.message === 'This action is unauthorized.') {
            //   mess = 'Esta acción no está autorizada.';
            // } else {
            //   mess = haldleErrorObj.error;
            // }

            break;
          default: {
            //  console.log('err ' + haldleErrorObj.error);
          }
        }
      }

      // Let the app keep running by returning a safe result.


      return throwError(mess);
    };

  }
}
