import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FormBuilder, FormControl } from '@angular/forms';


import { Notification, NotificationGeneric } from 'src/app/models/notification.model';
import { CommonService } from 'src/app/services/common.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';


@Component({
  selector: 'vex-notification-view',
  templateUrl: './notification-view.component.html',
  styleUrls: ['./notification-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationViewComponent implements OnInit {

  dataShare: Notification;

  form = this.fb.group({
    title: null,
    description: null,

  });

  commentCtrl = new FormControl();



  //iconos declarados


  constructor(private dialogRef: MatDialogRef<NotificationViewComponent>,
    @Inject(MAT_DIALOG_DATA) public noty: NotificationGeneric,
    private fb: FormBuilder,
    private cService: CommonService,
    private ts: TokenStorageService) {


  }

  ngOnInit() {



    const dataSend = {
      'id': this.noty.id
    };

    this.ts.isDataProfile().then((profile) => {

      this.cService.update('/notification-mark', profile.id, dataSend)
        .subscribe({
          next: (res) => { this.cService.show("Leídas ", 'success'); },
          error: (err) => { this.cService.show(err, 'error'); }
        });
    });

    this.form.valueChanges.subscribe(console.log);

    this.form.patchValue({
      title: this.noty.data.name,
      description: this.noty.data.description,

    });


  }

  save() {
    this.dialogRef.close(this.form.value);
  }





}
